import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ModalController } from '@ionic/angular';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-call',
    templateUrl: './modal-call.component.html',
    styleUrls: ['./modal-call.component.scss'],
})
export class ModalCallComponent implements AfterViewInit, OnDestroy {

    public isLoading = true;
    private _roomId;

    @Input() set roomId(value: string) {
        this._roomId = value;
    }

    @ViewChild('jitsi') jitsi: ElementRef<HTMLElement>;
    private ngUnsubscribe = new Subject();
    private jitsiApi;

    constructor(private modalController: ModalController, private userService: UserService, private afsFunctions: AngularFireFunctions) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.jitsiApi.executeCommand('hangup');
        this.jitsiApi.dispose();
        this.jitsi.nativeElement.innerHTML = null;
    }

    ngAfterViewInit() {
        const domain = environment.jitsi.domain;
        const userInfo$ = this.userService.user$.pipe(take(1));
        const token$ = this.afsFunctions.httpsCallable('getToken')({ confId: this._roomId, });

        combineLatest([userInfo$, token$]).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([user, token]) => {
                console.log('roomId', this._roomId);
                console.log('token', token);
                if (this.jitsiApi) {
                    this.jitsiApi.dispose();
                }
                const options = {
                    roomName: this._roomId,
                    width: '100%',
                    height: '100%',
                    jwt: token,
                    onload: this.loaded,
                    parentNode: this.jitsi.nativeElement,
                    configOverwrite: {
                        disableDeepLinking: true
                    }
                };
                this.jitsiApi = new (window as any).JitsiMeetExternalAPI(domain, options);
                this.jitsiApi.addListener('readyToClose', this.readyToClose);
            });
    }

    loaded = (f) => {
        this.isLoading = false;
    }

    readyToClose = () => {
        this.jitsiApi.dispose();
    }

    hangup() {
        this.jitsiApi.executeCommand('hangup');
        this.jitsiApi.dispose();
        setTimeout(() => {
            this.modalController.dismiss();
        }, 500);
    }

}
