import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { ModalMessengerGroupPlacementInfoComponent } from '../shared/modals/modal-messenger-group-placement-info/modal-messenger-group-placement-info.component';
import { BaseService } from './base.service';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class SystemNotificationService extends BaseService {
    constructor(
        private contextService: ContextService,
        private modalController: ModalController,
        private afs: AngularFirestore) {
        super(contextService);
    }
    init() {
        return this.afs.doc(`users/${this.currentUserUid}/systemNotifications/address`).valueChanges().pipe(
            tap(async (r: any) => {
                if (r) {
                    const locality = r.addressDetails.address_components.filter(a => a.types.find(aa => aa === 'locality')).map(a => a.long_name)[0];
                    const subLocality = r.addressDetails.address_components.filter(a => a.types.find(aa => aa === 'sublocality')).map(a => a.long_name)[0];
                    const modal = await this.modalController.create({
                        componentProps: {
                            locality,
                            subLocality,
                            userName: r.userName
                        },
                        component: ModalMessengerGroupPlacementInfoComponent,
                        cssClass: 'modal-styles messenger-group-placement-info',
                        backdropDismiss: false
                    });
                    modal.onWillDismiss().then(() => this.afs.doc(`users/${this.currentUserUid}/systemNotifications/address`).delete())
                    await modal.present();

                }


            })
        );
    }

}