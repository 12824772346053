import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface ObsWithStatusResult<T> {
    loading?: boolean;
    emptyArray?: boolean;
    emptyValue?: boolean;
    value?: any;
}

const defaultError = 'Something went wrong';

@Pipe({
    name: 'obsWithStatus',
})
export class ObsWithStatusPipe implements PipeTransform {
    transform<T = any>(val: Observable<T>): Observable<ObsWithStatusResult<T>> {
        return val.pipe(
            map((value: any) => {
                return {
                    loading: false,
                    value: value as any,
                    emptyArray: value && value.length ? value.length === 0 : true,
                    emptyValue: value === null
                };
            }),
            startWith({ loading: true }),
        );
    }
}
