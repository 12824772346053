import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {PageArticleData} from '../../../models/directus/page-article.model';
import {DirectusService} from '../../../services/directus.service';
import {privacyPage} from '../../../../environments/constants';

@Component({
    selector: 'app-modal-privacy-policy',
    templateUrl: './modal-privacy-policy.component.html',
    styleUrls: ['./modal-privacy-policy.component.scss']
})
export class ModalPrivacyPolicyComponent {

    pageArticle: Observable<PageArticleData>;

    constructor(private modalController: ModalController, private directusService: DirectusService) {
        this.pageArticle = directusService.getPageArticle(privacyPage);
    }

    public dismissModal() {
        this.modalController.dismiss();
    }

}
