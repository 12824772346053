import { Injectable } from '@angular/core';
import { AngularFirestore, } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { watch } from 'rxjs-watcher';
import * as firebase from 'firebase/app';
import { Message, Participant, RoomType, UserChatRoom } from '../models/messenger.model';
import { BaseService } from './base.service';
import { combineRoomsAndParticipants, getUserRooms, mapToChatRoom, sortRooms } from './rxjs-helpers/messenger.helper';
import { getEvenDaysDiff } from '../helpers/date.helper';
import { ContextService } from './context.service';
import { Timestamp } from '@firebase/firestore-types';
import { deleteFieldValue, serverTimestamp } from '../helpers/firebase.helper';
import { ContactModel } from '../models/contact.model';
import { removeEmpty } from '../helpers/object.helper';
import { Subject, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class MessengerService extends BaseService {
    private _currentRoom = new Subject<string>();
    private _unreadTotalCount = new Subject<number>();
    constructor(
        private contextService: ContextService,
        private angularFirestore: AngularFirestore,
        private angularfireFunction: AngularFireFunctions
    ) {
        super(contextService);
    }
    public get currentUserId() {
        return this.currentUserUid;
    }
    public get newRoomId() {
        return this.angularFirestore.createId();
    }
    public setCurrentRoomId(roomId: string) {
        this._currentRoom.next(roomId);
    }
    public resetCurrentRoom() {
        this._currentRoom.next('toto');
    }
    public updateLastReadRoomDate(roomId: string) {
        return this.angularFirestore.doc(`chat_rooms/${roomId}/participants/${this.currentUserUid}`).update({ lastRead: firebase.default.firestore.FieldValue.serverTimestamp() }).catch(() => { });
    }
    public get currentRoom() {
        return this._currentRoom.asObservable();
    }
    public setAdminForRoom(userId, roomId) {
        return this.angularFirestore.collection('chat_rooms').doc(roomId).update({ createdBy: userId });
    }
    public removeUserFromRoom(userId, roomId) {
        return this.angularFirestore.collection('chat_rooms').doc(roomId).collection('participants').doc(userId ?? this.currentUserUid).delete();
    }
    public async addContactsToRoom(users: ContactModel[], roomId: string) {
        return this.mapUserListToParticipantsPromise(roomId, users);
    }
    public async updateRoomAvatar(avatar: any, roomId) {
        return this.angularFirestore.collection('chat_rooms').doc(roomId).update({ avatar: avatar });
    }
    public async updateRoomName(roomName, roomId) {
        return this.angularFirestore.collection('chat_rooms').doc(roomId).update({ title: roomName });
    }
    public async createGroupRoom(room: any, isNew: boolean, usersList: ContactModel[]) {
        const { roomName, avatar } = removeEmpty(room);
        const data = removeEmpty({ title: roomName?.trim(), avatar });
        if (isNew)
            return this.angularFirestore.collection('chat_rooms').doc(room.id)
                .set({ ...data, type: RoomType.personGroup, createdBy: this.currentUserUid, createdAt: serverTimestamp })
                .then(() => this.mapUserListToParticipantsPromise(room.id, usersList));
        else
            return this.angularFirestore.collection('chat_rooms').doc(room.id)
                .update(data)
                .then(() => this.mapUserListToParticipantsPromise(room.id, usersList));
    }
    private mapUserListToParticipantsPromise(roomId: string, usersList: ContactModel[]) {
        const users = usersList.filter(a => a.uid !== this.currentUserUid);
        return Promise.all(users.map(u => this.angularFirestore.collection('chat_rooms').doc(roomId).collection('participants').doc(u.uid).set({ uid: u.uid }, { merge: true }).catch(() => { })))
    }
    public async sendMessage(message: string, roomId: string, reply?: any) {
        if (!!!message) { return; }
        const r = {} as any;
        if (reply) {
            r.text = reply.text;
            r.color = reply.color;
            r.user_name = reply.user_name;
        }
        return this.angularFirestore.doc(`chat_rooms/${roomId}`).collection<Message>('messages').add({
            message,
            reply: r.text ? r : null,
            createdBy: this.currentUserUid,
            createdAt: firebase.default.firestore.FieldValue.serverTimestamp()
        });
    }
    public getRoomParticipants(roomId: string) {
        return this.angularFirestore.collection<ContactModel>(`chat_rooms/${roomId}/participants`).valueChanges({ idField: 'id' });
    }

    public getChatRoom(roomId: string) {

        return combineLatest([
            this.angularFirestore
                .collection<Message>(`chat_rooms/${roomId}/messages`,
                    ref => ref
                        .orderBy('createdAt', 'asc')
                        .limitToLast(40)
                ).valueChanges(),
            this.angularFirestore.collection<Participant>(`chat_rooms/${roomId}/participants`).valueChanges({ idField: 'id' }),
            this.angularFirestore.collection<Participant>(`chat_rooms/${roomId}/removedParticipants`).valueChanges({ idField: 'id' }),
        ]).pipe(
            map(([messages, users, removedUsers]) => {
                const combinedUsers = users.concat(removedUsers);
                const result = [];

                for (const r of messages) {
                    const evenDay = getEvenDaysDiff((r.createdAt as Timestamp)?.toDate() || new Date());
                    const d = result.find(rr => rr.evenDay === evenDay) ||
                        { evenDay, values: [], date: (r.createdAt as Timestamp)?.toDate() || new Date() };
                    d.values.push(r);
                    if (d.values.length === 1) {
                        result.push(d);
                    }
                }

                for (const group of result) {
                    let lastUser = null;
                    let messagesWithAvatar = { messages: [] } as any;
                    group.messageGroup = [];
                    for (const message of group.values) {
                        const user = combinedUsers.find(a => a.id === message.createdBy);
                        if (!!!user) continue;
                        const userName = `${user.firstName} ${user.lastName}`;
                        const msg = {
                            text: message.message,
                            created_at: message.createdAt?.toDate() || new Date(),
                            user_name: userName,
                            color: user.color,
                            reply: message.reply
                        };
                        if (lastUser === message.createdBy) {
                            delete msg.user_name;
                            messagesWithAvatar.messages.push(msg);
                        } else if (lastUser === null) {
                            messagesWithAvatar.direction = message.createdBy === this.currentUserUid ? 'right' : 'left';
                            messagesWithAvatar.css = message.createdBy === this.currentUserUid ? 'mark6-message-come-from-me' : 'mark6-message-come-from-others';
                            messagesWithAvatar.isCurrentUser = message.createdBy === this.currentUserUid;
                            messagesWithAvatar.user_id = message.createdBy;
                            messagesWithAvatar.color = user.color;
                            messagesWithAvatar.gender = user.gender;
                            messagesWithAvatar.user_name = userName;
                            messagesWithAvatar.user_avatar = user.avatar;
                            messagesWithAvatar.messages.push(msg);
                        } else if (lastUser !== message.createdBy) {
                            group.messageGroup.push(messagesWithAvatar);
                            messagesWithAvatar = { messages: [] } as any;
                            messagesWithAvatar.user_id = message.createdBy;
                            messagesWithAvatar.isCurrentUser = message.createdBy === this.currentUserUid;
                            messagesWithAvatar.direction = message.createdBy === this.currentUserUid ? 'right' : 'left';
                            messagesWithAvatar.css = message.createdBy === this.currentUserUid ? 'mark6-message-come-from-me' : 'mark6-message-come-from-others';
                            messagesWithAvatar.user_name = userName;
                            messagesWithAvatar.gender = user.gender;
                            messagesWithAvatar.color = user.color;
                            messagesWithAvatar.user_avatar = user.avatar;
                            messagesWithAvatar.messages.push(msg);
                        }
                        lastUser = message.createdBy;
                    }
                    if (messagesWithAvatar.messages.length > 0)
                        group.messageGroup.push(messagesWithAvatar);
                    delete group.values;
                    group.evenDay; // usefull to trackby
                }
                return result;


            }));



    }





    public getUserRooms = () => getUserRooms(this.angularFirestore, this.currentUserUid).pipe(
        //filterCachedAndPendingWritesRecords,
        combineRoomsAndParticipants(this.angularFirestore),
        mapToChatRoom(this.currentUserUid)
    )





    public getChatMeeting(userId: string) {
        return this.angularfireFunction.httpsCallable('getMeetingUrl')(null).pipe(watch('getChatMeeting', 5));
    }






    public getChatRoomOneToOneId(userA: string, userB: string) {
        const users = [userA, userB].sort();
        return users.join('-');
    }




    public getChatRoomId(userId: string) {

        return combineLatest([
            of(this.currentUserUid),
            of(userId)
        ]).pipe(
            map(([currentUserId, contactId]) => {
                return this.getChatRoomOneToOneId(currentUserId, contactId);
            })
        );

    }

    public setUnreadTotalCount(count) {
        this._unreadTotalCount.next(count);
    }
    public get unreadTotalCount() {
        return this._unreadTotalCount.asObservable();
    }










}
