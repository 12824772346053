import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {Observable, Subscription, BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, map, shareReplay, startWith} from 'rxjs/operators';
import {ContactModel} from 'src/app/models/contact.model';
import {filterFromQueryWithSelectedUsers} from 'src/app/pages/contacts/rxjs.helper';
import {ContactService} from 'src/app/services/contact.service';
import {LabelService} from 'src/app/services/label.service';
import {ObsWithStatusResult, wrapWithLoadingInformation} from 'src/app/services/rxjs-helpers/common.helper';
import {UserService} from 'src/app/services/user.service';
import firebase from 'firebase';

@Component({
    selector: 'app-contacts-select-list',
    templateUrl: './contacts-select-list.component.html',
    styleUrls: ['./contacts-select-list.component.scss'],
})
export class ContactsSelectListComponent {

    contacts$: Observable<ObsWithStatusResult<ContactModel[]>>;
    currentUser$: Promise<firebase.User>;
    routeSubscription: Subscription;
    searchForm = new FormGroup({
        query: new FormControl()
    });
    searchValue$: Observable<string>;
    labels$: Observable<any>;
    currentLabel = new BehaviorSubject<string>('Alle');
    selectedUsers$ = new BehaviorSubject<ContactModel[]>(null);

    @Output() contacts = new EventEmitter<ContactModel[]>();

    @Input() set selectedUsers(value: ContactModel[]) {
        this.selectedUsers$.next(value);
    }

    constructor(public modalController: ModalController,
                private labelService: LabelService,
                private userService: UserService,
                private contactService: ContactService) {
        this.currentUser$ = this.userService.getCurrentUser();
        this.searchValue$ = this.searchForm.valueChanges.pipe(
            map(sf => sf.query as string),
            debounceTime(350),
            startWith(null),
            shareReplay());
        const contacts = this.contactService.getContacts();
        this.contacts$ = combineLatest([
            this.searchValue$,
            contacts,
            this.currentLabel.asObservable(),
            this.selectedUsers$
        ]).pipe(
            filterFromQueryWithSelectedUsers,
            wrapWithLoadingInformation
        );
        this.labels$ = this.labelService.getLabels();
    }

    emitContactList(contacts: ContactModel[]) {
        this.contacts.emit(contacts);
    }

    trackByContactId = (index: number, item: ContactModel) => {
        return item.uid;
    }

}
