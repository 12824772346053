import { Component, Input } from '@angular/core';
import { UserModel } from '../../../models/user.model';
import {AlertController, ModalController, Platform, PopoverController} from '@ionic/angular';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MessengerService } from '../../../services/messenger.service';
import { TranslocoService } from '@ngneat/transloco';
import { ContactModel } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/services/contact.service';
import { of, timer } from 'rxjs';
import { RequestStatus } from 'src/app/models/request.model';
import { ContactLabelsEditComponent } from '../../components/contact-labels-edit/contact-labels-edit.component';
import { DatePipe } from '@angular/common';
import { CallStatus } from 'src/app/models/call.model';
import { ModalIncomingCallComponent } from '../modal-incoming-call/modal-incoming-call.component';
import { CallService } from 'src/app/services/call.service';
import { ModalCallComponent } from '../modal-call/modal-call.component';
import {ModalContactLabelsEditComponent} from '../modal-contact-labels-edit/modal-contact-labels-edit.component';

@Component({
    selector: 'app-modal-contact-detail',
    templateUrl: './modal-contact-detail.component.html',
    styleUrls: ['./modal-contact-detail.component.scss'],
})
export class ModalContactDetailComponent {
    requestStatus = RequestStatus;
    @Input() contact: ContactModel;

    actions = [
        {
            icon: '/assets/icons/feather/mail.svg',
            title: this.translocoService.translate('common.email'),
            hide: () => false,
            property: (contact) => contact.email,
            function: (contact, event) => location.href = 'mailto:' + contact.email,
            error: (contact) => this.translocoService.translate('error.contact_detail.email')
        },
        {
            icon: '/assets/icons/feather/smartphone.svg',
            title: this.translocoService.translate('common.number_mobile'),
            hide: () => false,
            property: (contact) => contact.mobileNumber,
            function: (contact, event) => location.href = 'tel:' + contact.mobileNumber,
            error: (contact) => this.translocoService.translate('error.contact_detail.number_mobile')
        },
        {
            icon: '/assets/icons/feather/phone.svg',
            title: this.translocoService.translate('common.number_home'),
            hide: () => false,
            property: (contact) => contact.phoneNumber,
            function: (contact, event) => location.href = 'tel:' + contact.phoneNumber,
            error: (contact) => this.translocoService.translate('error.contact_detail.number_home')
        },
        {
            icon: '/assets/icons/feather/gift.svg',
            title: this.translocoService.translate('common.birthday'),
            hide: () => false,
            property: (contact) => this.datePipe.transform(contact.birthDate?.toDate(), 'dd.MM'),
            function: (contact, event) => location.href = 'mailto:' + contact.email,
            error: (contact) => this.translocoService.translate('error.contact_detail.birthday')
        },
        {
            icon: '/assets/icons/feather/message-square.svg',
            title: this.translocoService.translate('common.messenger'),
            hide: () => this.contact?.requestStatus !== RequestStatus.accepted,
            property: (contact) => this.translocoService.translate('common.message_send'),
            function: (contact, event) => this.message(contact.uid),
            error: (contact) => this.translocoService.translate('error.contact_detail.uid')
        },
        {
            icon: '/assets/icons/feather/video.svg',
            title: this.translocoService.translate('common.call_video'),
            hide: () => this.contact?.requestStatus !== RequestStatus.accepted,
            property: (contact) => false, // TODO ALEX IMPORTANT: ADD TEXT 'AVAILABLE FOR CALL IF USER IS AVAILABLE
            function: (contact, event) => this.callService.call(contact),
            error: (contact) => this.translocoService.translate('error.contact_detail.call_video')
        },
        {
            icon: '/assets/icons/feather/phone.svg',
            title: this.translocoService.translate('common.call_voice'),
            hide: () => this.contact?.requestStatus !== RequestStatus.accepted,
            property: (contact) => false, // TODO ALEX IMPORTANT: ADD TEXT 'AVAILABLE FOR CALL IF USER IS AVAILABLE
            function: (contact, event) => console.log(contact), // TODO ALEX IMPORTANT: ADD THE FUNCTION FOR VOICE CALL
            error: (contact) => this.translocoService.translate('error.contact_detail.call_voice')
        },
        {
            icon: '/assets/icons/feather/tag.svg',
            title: this.translocoService.translate('common.labels'),
            hide: () => this.contact?.requestStatus !== RequestStatus.accepted,
            property: (contact) => contact?.labels ? contact.labels.join(', ') : false,
            function: (contact, event) => this.openPopoverEditLabels(event),
            error: (contact) => this.translocoService.translate('error.contact_detail.labels')
        },
    ];


    async callAccepted(roomId) {
        const modal = await this.modalCtrl.create({
            component: ModalCallComponent,
            componentProps: { roomId },
            cssClass: 'modal-styles call',
            backdropDismiss: false
        });
        await modal.present();
    }
    constructor(
        private callService: CallService,
        private datePipe: DatePipe,
        private modalCtrl: ModalController,
        private router: Router,
        private contactService: ContactService,
        private messengerService: MessengerService,
        private translocoService: TranslocoService,
        private alertController: AlertController,
        protected transloco: TranslocoService,
        public platform: Platform,
        public popoverController: PopoverController
    ) {
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    toggleFavourite() {
        this.contact.favourite = !this.contact.favourite;
        this.contactService.setFavourite(this.contact);
    }
    async createContactRequest() {
        this.contactService.createContactRequest(this.contact.uid);
        this.contact.requestStatus = RequestStatus.pending;
    }
    async presentAlertDeleteContact() {
        const alert = await this.alertController.create({
            cssClass: 'alert-styles',
            header: this.transloco.translate('contacts.delete.alert.header'),
            message: this.transloco.translate('contacts.delete.alert.paragraph', { contactName: `${this.contact.firstName} ${this.contact.lastName}` }),
            buttons: [
                {
                    text: this.transloco.translate('common.cancel'),
                    role: 'cancel',
                    handler: () => {
                        // console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: this.transloco.translate('common.delete'),
                    handler: () => {
                        this.contactService.delete(this.contact.uid).then(() => timer(500).toPromise()).then(() => this.dismissModal());
                    }
                }
            ]
        });
        await alert.present();
    }

    // @TODO ALEX - PLEASE CHECK THIS FUNCTION IF I DO EVERYTHING CORRECTLY
    public message(userId) {
        this.messengerService.getChatRoomId(userId).pipe(
            take(1),
        ).subscribe(chatId => this.router.navigate(['/messenger', chatId]));
        // Close Modal after redirecting to Messenger
        this.dismissModal();
    }

    async openPopoverEditLabels(event: Event) {
        if (this.platform.is('desktop')) {
            const popover = await this.popoverController.create({
                component: ContactLabelsEditComponent,
                componentProps: { contact: this.contact },
                event,
            });
            popover.onWillDismiss().then(() => {
                console.log(popover.componentProps.contact);
            });
            await popover.present();
        } else {
            const modal = await this.modalCtrl.create({
                component: ModalContactLabelsEditComponent,
                componentProps: { contact: this.contact }
            });
            modal.onWillDismiss().then(() => {
            });
            await modal.present();
        }
    }

}
