import {Component} from '@angular/core';
import {ZoomService} from '../../services/zoom.service';
import {Platform} from '@angular/cdk/platform';

@Component({
    selector: 'app-layout-authentication',
    templateUrl: './layout-authentication.component.html',
    styleUrls: ['./layout-authentication.component.scss'],
})
export class LayoutAuthenticationComponent {

    constructor(
        private zoomService: ZoomService,
        public platform: Platform
    ) {
    }

    changeZoomLevel() {
        if (this.zoomService.currentActive() === '1') {
            this.zoomService.update('2');
        } else {
            this.zoomService.update('1');
        }
    }

}
