import { FormControl } from "@angular/forms";



export const noWhitespaceValidator = (minLength: number) => (control: FormControl) => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isMinLength = (control.value || '').trim().length < minLength;
    const isValid = !isWhitespace && !isMinLength;
    if (isValid) return null;
    if (isWhitespace) return { required: true };
    if (isMinLength) return { minlength: true };
}
