import { Timestamp } from '@firebase/firestore-types';
import { Gender, UserModel } from './user.model';



export interface DataBaseContactRequest {
    contactId: string;
    requestedAt: Timestamp;
    requestedBy: string;
    accepted?: boolean;
}
export interface DataBaseContactRequestWithUserId extends DataBaseContactRequest {
    userId: string;
}
export interface DataBaseContactRequestExtended extends DataBaseContactRequest {
    requestor: UserModel;
    isWorking?: boolean;
    isComplete?: boolean;
    isDeclined?: boolean;
    isAccepted?: boolean;
}
export interface ContactRequest {
    evenDay: number;
    values: DataBaseContactRequestExtended[];
    date: Date;
}

export interface ContactSearch {
    firstName: string;
    lastName: string;
    uid: string;
    livingTown?: string;
    requestStatus?: RequestStatus;
    avatar: any;
    gender: Gender;
}
export enum RequestStatus {
    accepted, pending
}

export interface AlgoliaContactSearch {
    objectID: string;
    firstName: string;
    lastName: string;
    id: string;
    livingTown: string;
    avatar: any;
    gender: Gender;
}