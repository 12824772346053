import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { delay, take, tap } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class ContextService {
    authState: firebase.default.User;
    constructor(private afsAuth: AngularFireAuth) {
    }
    public init() {
        this.afsAuth.authState.subscribe((authState) => this.authState = authState);
        return this.afsAuth.authState.pipe(take(1));
    }

}