import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'base64'
})

export class Base64Pipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (!!!value) return null;
        return 'data:image/jpg;base64,' + value;

    }
}
