import { AngularFireAuth } from '@angular/fire/auth';
import { IonThumbnail } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { ContextService } from './context.service';
import { MessengerService } from './messenger.service';
import { log } from './rxjs-helpers/common.helper';
export abstract class BaseService {
    constructor(private ctx: ContextService) {
    }
    public get authState(){
        return this.ctx.authState;
    }
    protected get currentUserUid() {
        return this.ctx.authState?.uid;
    }

}
