import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormGroup } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { watch } from 'rxjs-watcher';
import { tap, catchError } from 'rxjs/operators';
import { UserChatRoom } from '../models/messenger.model';
import { BaseService } from './base.service';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class FileService extends BaseService {
    private uploadStatus = new Subject<number>();


    constructor(
        private http: HttpClient,
        private contextService: ContextService,
        private angularFireFunctions: AngularFireFunctions,
        private angularFirestore: AngularFirestore) {
        super(contextService);
    }

    public get uploadStatus$() {
        return this.uploadStatus.asObservable();
    }

    public async uploadRoomAvatar(avatar: File, form: FormGroup) {
        // const tokenData = await this.fns.httpsCallable('getStorageToken')(null).toPromise();
        const minioResult = await this.angularFireFunctions.httpsCallable('getSignedUrl')({ type: 'avatar' }).toPromise();
        console.log('minioResult', minioResult);
        // const { hash, expire } = tokenData;
        const { url, result, preview } = minioResult;
        return new Promise((resolve, reject) => {
            this.http.put(url, avatar, { reportProgress: true, observe: 'events' })
                .pipe(
                    tap(console.log),
                    tap(this.handleProgress),
                    tap((r) => this.handleRoomUploadDone(r, result, preview, form,resolve)),
                    catchError(this.handleError),
                    watch('uploadAvatar')
                ).subscribe((v) => {
                    console.log('minio events', v)
                });
        })

    }

    public async uploadAvatar(avatar: File) {

        // const tokenData = await this.fns.httpsCallable('getStorageToken')(null).toPromise();
        const minioResult = await this.angularFireFunctions.httpsCallable('getSignedUrl')({ type: 'avatar' }).toPromise();
        console.log('minioResult', minioResult);
        // const { hash, expire } = tokenData;
        const { url, result, preview } = minioResult;
        this.http.put(url, avatar, { reportProgress: true, observe: 'events' })
            .pipe(
                tap(console.log),
                tap(this.handleProgress),
                tap((r) => this.handleUploadDone(r, result, preview)),
                catchError(this.handleError),
                watch('uploadAvatar')
            ).subscribe((v) => console.log('minio events', v));
    }


    private finalize = () => {
        this.uploadStatus.next(null);
    }

    private handleProgress = (r) => {
        if (r.type === 1) {
            this.uploadStatus.next(Math.floor((r.loaded / r.total) * 100));
        }
    }

    private handleUploadDone = async (r, sizes, preview) => {
        console.log(r,);
        if (r.type === 4) {

            // const url = `https://proxy.anime.chat/insecure/fill/500/500/sm/0/${s3Url}`
            // const url = getPreviewImageUrl(r.body.file, c.data);
            const b64 = await this.getBase64ImageFromURL(preview);

            await this.angularFirestore.doc(`users/${this.currentUserUid}`).update({ avatar: { sizes, preview: b64 } });
            this.finalize();
        }
    }
    private handleRoomUploadDone = async (r, sizes, preview, form: FormGroup,resolve) => {
        console.log(r,);
        if (r.type === 4) {

            // const url = `https://proxy.anime.chat/insecure/fill/500/500/sm/0/${s3Url}`
            // const url = getPreviewImageUrl(r.body.file, c.data);
            const b64 = await this.getBase64ImageFromURL(preview);
            form.patchValue({ avatar: { sizes, preview: b64 } });
            this.finalize();
            resolve({ avatar: { sizes, preview: b64 } });
        }
    }

    private handleError = (r) => {
        console.log(r);
        return of(null);
    }


    private getBase64ImageFromURL = (url: string) => {
        return new Promise((resolve, reject) => {


            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.src = url;
            if (!img.complete) {
                img.onload = () => {
                    resolve(this.getBase64Image(img));
                };
                img.onerror = (err) => {
                    reject(err);
                };
            } else {
                resolve(this.getBase64Image(img));
            }

        });

    };


    private getBase64Image = (img: HTMLImageElement) => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        console.log(dataURL);
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    }

}
