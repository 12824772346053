import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchBarService {
    private searchBarValueSubject = new ReplaySubject<string>(1);
    constructor() { }

    getSearchBarValue() {
        return this.searchBarValueSubject.asObservable();
    }
    setSearchBarValue(value) {
        this.searchBarValueSubject.next(value);
    }
}