import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SearchBarService} from './search-bar.service';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnDestroy {

    private destroyed = new Subject();

    form = new FormGroup({
        search: new FormControl(null, Validators.required)
    });

    constructor(private router: Router, private searchBarService: SearchBarService) {
        this.searchBarService.getSearchBarValue()
            .pipe(takeUntil(this.destroyed))
            .subscribe((value) => this.form.controls.search.setValue(value));
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    submit() {
        console.log('submit', this.form.value);
        if (this.form.valid) {
            this.router.navigate(['search', this.form.value.search]);
        }
    }

}
