import { AngularFirestore } from "@angular/fire/firestore";
import { map, switchMap, tap } from "rxjs/operators";
import { getEvenDaysDiff } from "src/app/helpers/date.helper";
import { ContactModelDataBase, ContactModel } from "src/app/models/contact.model";
import { ContactRequest, DataBaseContactRequest, DataBaseContactRequestExtended } from "src/app/models/request.model";
import { UserModel } from "src/app/models/user.model";
import { joinWithUsers } from "./common.helper";

export const mapContactsToUsers = (afs: AngularFirestore, currentUserId) =>
    afs.collection<ContactModelDataBase>(`users/${currentUserId}/contacts`).valueChanges().pipe(
        joinWithUsers(afs, 'contactId'),
        map(([contacts, users]) => {
            if ((!!!contacts || contacts.length === 0) && (!!!users || users.length === 0)) {
                return null;
            }
            const result: ContactModel[] = [];
            for (const user of users) {
                result.push({
                    ...user,
                    favourite: contacts.find(c => c.contactId === user.uid)?.favourite || false,
                    labels: contacts.find(c => c.contactId === user.uid)?.labels || []
                } as ContactModel)
            }
            return result;
        }),
    )

export const mapToContactRequest = map(([requests, users]: [DataBaseContactRequest[], UserModel[]]) => {
    let result: ContactRequest[] = [];
    for (const request of requests) {
        const evenDay = getEvenDaysDiff(request.requestedAt.toDate()) > 0 ? 99 : 0;
        const d = result.find(r => r.evenDay === evenDay) || { evenDay: evenDay, values: [] as DataBaseContactRequestExtended[], new: true, date: request.requestedAt.toDate() };
        d.values.push({ ...request, requestor: users.find(u => u.uid === request.requestedBy) } as DataBaseContactRequestExtended);
        if (d.values.length === 1) { result.push(d); }
    }
    return result.length > 0 ? result : null;
})

export const getUserContactRequests = (afs: AngularFirestore, currentUserId: string) =>
    afs.
        collection('users').
        doc(currentUserId).
        collection<DataBaseContactRequest>('contactRequests',
            ref => ref
                .where('accepted', '==', null)
                .orderBy('requestedAt', 'desc'))
        .valueChanges()