import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { BaseService } from './base.service';
import { arrayRemoveFieldValue, arrayUnionFieldValue } from '../helpers/firebase.helper';
import { map, switchMap } from 'rxjs/operators';
import { ContactModel } from '../models/contact.model';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class LabelService extends BaseService {



    constructor(
        private contextService: ContextService,
        private afs: AngularFirestore) {
        super(contextService);
    }


    setLabel(label: string, contact: ContactModel, value: boolean) {
        const batch = this.afs.firestore.batch();
        const v = value ? arrayUnionFieldValue(label) : arrayRemoveFieldValue(label);
        batch.set(this.afs.doc(`users/${this.currentUserUid}/contacts/${contact.uid}`).ref, { labels: v }, { merge: true });
        if (value) { batch.set(this.afs.doc(`users/${this.currentUserUid}/labels/values`).ref, { labels: v }, { merge: true }); }
        return batch.commit();
    }



    getLabels() {
        return this.afs.doc<any>(`users/${this.currentUserUid}/labels/values`).valueChanges().pipe(map(d => d.labels));
    }

    delete(contacts: ContactModel[], label: string) {
        const batch = this.afs.firestore.batch();
        batch.set(this.afs.doc(`users/${this.currentUserUid}/labels/values`).ref, { labels: arrayRemoveFieldValue(label) }, { merge: true });
        for (const contact of contacts) {
            batch.set(this.afs.doc(`users/${this.currentUserUid}/contacts/${contact.uid}`).ref, { labels: arrayRemoveFieldValue(label) }, { merge: true });
        }
        return batch.commit();
    }
    deleteContactAndLabel(contacts: ContactModel[], key: string) {
        const batch = this.afs.firestore.batch();
        batch.set(this.afs.doc(`users/${this.currentUserUid}/labels/values`).ref, { labels: arrayRemoveFieldValue(key) }, { merge: true });
        for (const contact of contacts) {
            batch.delete(this.afs.doc(`users/${this.currentUserUid}/contacts/${contact.uid}`).ref);
        }
        return batch.commit();
    }
}
