import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LayoutDefaultComponent} from './layouts/layout-default/layout-default.component';
import {LayoutAuthenticationComponent} from './layouts/layout-authentication/layout-authentication.component';
import {LayoutDefaultPageComponent} from './layouts/layout-default-page/layout-default-page.component';
import {AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {WelcomeDoneGuard, WelcomeNotDoneGuard} from './shared/guards/welcome.guards';
import {ContextGuard} from './shared/guards/context.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/sign-in']);
const redirectAuthorizedToHome = () => redirectLoggedInTo(['/']);

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {
        path: '',
        component: LayoutDefaultComponent,
        canActivate: [ContextGuard, AngularFireAuthGuard, WelcomeDoneGuard], data: {authGuardPipe: redirectUnauthorizedToLogin},
        children: [
            {
                path: '',
                component: LayoutDefaultPageComponent,
                children: [
                    {
                        path: 'home',
                        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
                    },
                    {
                        path: 'home-schlaue-buerger',
                        loadChildren: () => import('./pages/home-schlaue-buerger/home.module').then(m => m.HomePageModule)
                    },
                    {
                        path: 'meet',
                        loadChildren: () => import('./pages/jitsi/jitsi.module').then(m => m.JitsiPageModule)
                    },
                    {
                        path: 'search',
                        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
                    },
                    {
                        path: 'contacts',
                        loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule)
                    },
                    {
                        path: 'messenger',
                        loadChildren: () => import('./pages/messenger/messenger.module').then(m => m.MessengerPageModule)
                    },
                    {
                        path: 'legal-notice',
                        loadChildren: () => import('./pages/law/legal-notice/legal-notice.module').then(m => m.LegalNoticePageModule)
                    },
                    {
                        path: 'privacy-policy',
                        loadChildren: () => import('./pages/law/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
                    },
                    {
                        path: 'settings',
                        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
                    }
                ]
            }
        ]
    },
    {
        path: 'auth',
        component: LayoutAuthenticationComponent,
        children: [
            {
                path: 'sign-in',
                canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectAuthorizedToHome},
                loadChildren: () => import('./pages/auth/sign-in/sign-in.module').then(m => m.SignInPageModule)
            },
            {
                path: 'sign-up',
                canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectAuthorizedToHome},
                loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpPageModule)
            },
            {
                path: 'sign-out',
                loadChildren: () => import('./pages/auth/sign-out/sign-out.module').then(m => m.SignOutPageModule)
            }
        ]
    },
    {
        path: 'welcome',
        canActivate: [WelcomeNotDoneGuard],
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    {path: '**', redirectTo: '/home'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
