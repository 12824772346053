import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutDefaultComponent } from './layouts/layout-default/layout-default.component';
import { SharedModule } from './shared/shared.module';
import { LayoutAuthenticationComponent } from './layouts/layout-authentication/layout-authentication.component';
import { LayoutDefaultPageComponent } from './layouts/layout-default-page/layout-default-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@angular/common/locales/global/de';
import { FirebaseModule } from './firebase.module';
// import {ServiceWorkerModule} from '@angular/service-worker';

@NgModule({
    declarations: [
        AppComponent,
        LayoutDefaultComponent,
        LayoutDefaultPageComponent,
        LayoutAuthenticationComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        SharedModule,
        FirebaseModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'de' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
