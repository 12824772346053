import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';
import {UserService} from 'src/app/services/user.service';

@Injectable({providedIn: 'root'})
export class WelcomeDoneGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.user$.pipe(map(user => {
            if (!!!user || !!!user.tutorialDone) {
                this.router.navigate(['/welcome']);
                return false;
            } else {
                return user.tutorialDone;
            }
        }));
    }

}

@Injectable({providedIn: 'root'})
export class WelcomeNotDoneGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.user$.pipe(map(user => {
            if (user && user.tutorialDone) {
                this.router.navigate(['/home']);
                return false;
            } else {
                return true;
            }
        }));
    }

}
