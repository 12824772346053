import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
    selector: 'app-messenger-message-menu',
    templateUrl: './messenger-message-menu.component.html',
    styleUrls: ['./messenger-message-menu.component.scss'],
})
export class MessengerMessageMenuComponent {

    constructor(public popoverController: PopoverController) {
    }

    public reply() {
        console.log('Reply Message');
    }

    public closePopover() {
        this.popoverController.dismiss();
    }

}
