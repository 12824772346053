import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';

@NgModule({
    imports: [
        // Firebase
        AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        AngularFirePerformanceModule,
        AngularFireAnalyticsModule,
        AngularFireMessagingModule
    ],
    exports: [
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        AngularFirePerformanceModule,
        AngularFireAnalyticsModule,
        AngularFireMessagingModule
    ],
    providers: [
        PerformanceMonitoringService,
        ScreenTrackingService,
        UserTrackingService,
        { provide: REGION, useValue: 'europe-west3' },
    ]
})
export class FirebaseModule {
}
