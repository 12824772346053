import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-modal-contact-request-notifications',
    templateUrl: './modal-contact-request-notifications.component.html',
    styleUrls: ['./modal-contact-request-notifications.component.scss'],
})
export class ModalContactRequestNotificationsComponent implements OnInit {

    constructor(private modalCtrl: ModalController) {
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    ngOnInit() {
    }

}
