import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {PageArticle} from '../models/directus/page-article.model';
import {DomSanitizer} from '@angular/platform-browser';

interface Params {
    name: string;
    value: string;
}

const headers = {headers: {Authorization: environment.directus.publicToken}};

@Injectable({
    providedIn: 'root'
})
export class DirectusService {

    constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {}

    getPageArticle(id) {
        return this.httpClient.get<PageArticle>(DirectusService.buildUrl('items/pages/' + id), headers)
            .pipe(map(d => {
                const regex = /(\s*(?:@[^{]+{\s*)?)([^{]+)({[^}]+}\s*}?)/g;
                let css = '';
                let output: string[];

                while (output = regex.exec(d.data.custom_css as string)) {
                    css += output[1] + output[2].split(',').map(val => ` article.article-${d.data.id} ${val}`).join(',') + output[3];
                }

                d.data.custom_css = this.domSanitizer.bypassSecurityTrustHtml(`<style>${css}</style>`);
                return d.data;
            }));
    }

    private static buildUrl(path?: string, params?: Params[]) {
        const result = new URL(path, environment.directus.url);
        if (params) {
            for (const param of params) {
                result.searchParams.append(param.name, param.value);
            }
        }
        console.info('Get Article from: ' + result);
        return result.toString();
    }
}
