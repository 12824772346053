import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { TranslocoRootModule } from './transloco-root.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ModalPrivacyPolicyComponent } from './modals/modal-privacy-policy/modal-privacy-policy.component';
import { Mark6CropperModule } from '@devmonkeys/mark6/cropper';
import { Mark6ImageModule } from '@devmonkeys/mark6/image';
import { PlatformModule } from '@angular/cdk/platform';
import { ModalMyProfileComponent } from './modals/modal-my-profile/modal-my-profile.component';
import { CardComponent } from './components/card/card.component';
import { GenericPageComponent } from './components/generic-page/generic-page.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { Mark6MessengerModule } from '@devmonkeys/mark6/messenger';
import { ModalAddContactsComponent } from './modals/modal-add-contacts/modal-add-contacts.component';
import { ModalContactDetailComponent } from './modals/modal-contact-detail/modal-contact-detail.component';
import { SearchResultCardComponent } from './components/search-result-card/search-result-card.component';
import { ModalNotificationsComponent } from './modals/modal-notifications/modal-notifications.component';
import { ModalContactRequestNotificationsComponent } from './modals/modal-contact-request-notifications/modal-contact-request-notifications.component';
import { ObsWithStatusPipe } from './pipes/obs-with-status.pipe';
import { Mark6EditableImageModule } from '@devmonkeys/mark6/editable-image';
import { NotificationDateGroupPipe } from './modals/modal-notifications/notification-date-group.pipe';
import { MessengerRoomsListComponent } from './components/messenger-rooms-list/messenger-rooms-list.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { IonContentScrollBarStylesDirective } from './directives/ion-content-scroll-bar-styles.directive';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ModalMessengerGroupPlacementInfoComponent } from './modals/modal-messenger-group-placement-info/modal-messenger-group-placement-info.component';
import { ModalIncomingCallComponent } from './modals/modal-incoming-call/modal-incoming-call.component';
import { ModalCallComponent } from './modals/modal-call/modal-call.component';
import { MessengerComponent } from './components/messenger/messenger.component';
import { MessengerMessageMenuComponent } from './components/messenger-message-menu/messenger-message-menu.component';
import { FindInArrayPipe } from './pipes/find-in-array.pipe';
import { ContactLabelsEditComponent } from './components/contact-labels-edit/contact-labels-edit.component';
import { ContactsSelectListComponent } from './components/contacts-select-list/contacts-select-list.component';
import { ModalLabelDeleteConfirmationComponent } from './modals/modal-label-delete-confirmation/modal-label-delete-confirmation.component';
import { EqualsPipe } from './pipes/equals.pipe';
import { Mark6PhoneModule } from '@devmonkeys/mark6/phone';
import { MessageCountPipe } from './pipes/message-count.pipe';
import { MessengerAutoSizeDirective } from './directives/messenger-textarea-auto-size.directive';
import { ModalRoomInfoComponent, RoomMemberOptionsListComponent } from './modals/modal-room-info/modal-room-info.component';
import { FileSelectorDirective } from './directives/file-selector.directive';
import { DelayPipe } from './pipes/delay.pipe';
import { Base64Pipe } from './pipes/base64.pipe';
import { SizePipe } from './pipes/size.pipe';
import { SrcSetPipe } from './pipes/src-set.pipe';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { UsersListSortPipe } from './pipes/users-list-sort.pipe';
import {ModalContactLabelsEditComponent} from './modals/modal-contact-labels-edit/modal-contact-labels-edit.component';




// Import Modules here:
const sharedModules = [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    InlineSVGModule.forRoot(), // Documentation: https://github.com/arkon/ng-inline-svg
    TranslocoRootModule, // Documentation: https://netbasal.gitbook.io/transloco/
    // TranslocoLocaleModule.init(),
    Mark6ImageModule,
    Mark6CropperModule,
    Mark6MessengerModule.forRoot({ dateFormats: { block: { days: ['Heute', 'Gestern'] } } }),
    Mark6EditableImageModule,
    Mark6PhoneModule,
    // Mark6DirectivesModule,
    // Material
    PlatformModule
];

// Declare Components here:
const sharedComponents = [
    // ModalSetItemComponent,
    // ModalSetLocaleComponent,
    // ModalLegalNoticeComponent,
    ModalAddContactsComponent,
    ModalContactDetailComponent,
    ModalContactLabelsEditComponent,
    ModalContactRequestNotificationsComponent,
    ModalMyProfileComponent,
    ModalNotificationsComponent,
    ModalCallComponent,
    ModalIncomingCallComponent,
    ModalLabelDeleteConfirmationComponent,
    ModalMessengerGroupPlacementInfoComponent,
    ModalPrivacyPolicyComponent,
    ModalRoomInfoComponent,
    // Components
    AddressSearchComponent,
    CardComponent,
    ContactLabelsEditComponent,
    ContactsSelectListComponent,
    GenericPageComponent,
    MessengerComponent,
    MessengerMessageMenuComponent,
    MessengerRoomsListComponent,
    SearchBarComponent,
    SearchResultCardComponent,
    UserMenuComponent,
    UserSearchComponent,
    RoomMemberOptionsListComponent
];

// Declare Pipes here:
const sharedPipes = [
    ObsWithStatusPipe,
    NotificationDateGroupPipe,
    FindInArrayPipe,
    MessageCountPipe,
    EqualsPipe,
    DelayPipe,
    Base64Pipe,
    SizePipe,
    SrcSetPipe,
    UsersListSortPipe
];

const sharedDirectives = [
    IonContentScrollBarStylesDirective,
    MessengerAutoSizeDirective,
    FileSelectorDirective,
    IntersectionObserverDirective
];

// Why Shared Module? Check here: https://angular.io/guide/sharing-ngmodules
@NgModule({
    imports: [
        RouterModule,
        sharedModules
    ],
    declarations: [
        sharedComponents,
        sharedDirectives,
        sharedPipes
    ],
    exports: [
        sharedModules,
        sharedComponents,
        sharedDirectives,
        sharedPipes
    ],
    providers: [
        DatePipe
    ]
})
export class SharedModule {
}
