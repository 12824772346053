export const mapAvatar = (gender: any): any => {
    switch (gender) {
        case 'male':
            return 'https://schoenerland-app.web.app/assets/illustrations/undraw_male_avatar_323b.svg';
        case 'female':
            return 'https://schoenerland-app.web.app/assets/illustrations/undraw_female_avatar_w3jk.svg';
        case 'diverse':
            return 'https://schoenerland-app.web.app/assets/icons/custom/diverse_avatar.svg';
        case 'none':
            return 'https://schoenerland-app.web.app/assets/icons/custom/account_1.svg';
        default:
            return 'https://schoenerland-app.web.app/assets/icons/custom/account_1.svg';
    }
}