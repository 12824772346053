import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
// import {Mark6TextareaAutosizeDirective} from '@devmonkeys/mark6/directives';
import { ActionSheetController, IonContent, ModalController, Platform, PopoverController } from '@ionic/angular';
import { MessengerMessageMenuComponent } from '../messenger-message-menu/messenger-message-menu.component';
import { MessengerAutoSizeDirective } from '../../directives/messenger-textarea-auto-size.directive';
import { combineLatest, of, Subject } from 'rxjs';
import { take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { GlobalSearchResultItem } from '../../../models/global-search.model';
import { ModalContactDetailComponent } from '../../modals/modal-contact-detail/modal-contact-detail.component';
import { ContactService } from 'src/app/services/contact.service';
import { UserService } from '../../../services/user.service';
import { ModalMyProfileComponent } from '../../modals/modal-my-profile/modal-my-profile.component';


export interface MessengerMessageBlockInterface {
    date: Date;
    messageGroup: MessengerMessageGroupInterface[];
}

export interface MessengerMessageGroupInterface {
    type: string;
    user_id?: any;
    user_name?: string;
    user_avatar?: AvatarInterface;
    messages?: MessengerMessageInterface[];
}

export interface AvatarInterface {
    preview?: string;
    src?: string;
    srcset?: string;
    default?: string;
}

export interface MessengerMessageInterface {
    created_at?: Date;
    text: string;
    reply?: MessengerReplyInterface;
    user_name: string;
}

export interface MessengerReplyInterface {
    user_name: string;
    text: string;
    color?: string;
}

@Component({
    selector: 'app-messenger',
    templateUrl: './messenger.component.html',
    styleUrls: ['./messenger.component.scss'],
})
export class MessengerComponent implements AfterViewInit, OnChanges, OnDestroy {

    private atBottom$ = new Subject<boolean>();
    private checkScroll$ = new Subject<void>();
    private afterViewInit$ = new Subject<void>();
    private isFirstMessageBlockChange$ = new Subject<void>();
    private ngUnsubscribe = new Subject<void>();
    @ViewChild(IonContent) ionContent: IonContent;
    @ViewChild(MessengerAutoSizeDirective) MessengerAutoSize: MessengerAutoSizeDirective;
    @Input() public messageBlock: MessengerMessageBlockInterface[];
    @Output() sendClicked = new EventEmitter<[string, MessengerReplyInterface]>();
    public textareaValue = '';
    public reply: MessengerReplyInterface;

    // Messenger Settings
    public messengerBoxed = false;
    public avatarMe = true;
    public avatarOthers = true;
    public demoEditorState = false;
    public defaultElementSize = 1;
    public defaultFontSize = 16;
    public defaultAvatarSize = 36;

    constructor(
        private contactService: ContactService,
        private modalController: ModalController,
        public actionSheetController: ActionSheetController,
        public popoverController: PopoverController,
        public platform: Platform,
        private userService: UserService
    ) {
        // TODO better
        combineLatest([this.afterViewInit$, this.isFirstMessageBlockChange$])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.ionContent.scrollToBottom();
            });

        this.checkScroll$.pipe(
            withLatestFrom(this.atBottom$),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(([checkScroll, atBottom]) => {
            if (atBottom) {
                this.ionContent.scrollToBottom();
            }
        });
    }

    replyMessage(message, userName, color) {
        this.reply = { ...message };
        this.reply.color = color;
        this.reply.user_name = userName;
    }

    async toggleMessageOptions(message, ev: any) {
        if (this.platform.is('desktop')) {
            const popover = await this.popoverController.create({
                component: MessengerMessageMenuComponent,
                // cssClass: 'my-custom-class',
                event: ev,
                // translucent: true
            });
            return await popover.present();
        } else {
            const actionSheet = await this.actionSheetController.create({
                header: 'Albums',
                cssClass: 'my-custom-class',
                buttons: [
                    {
                        text: 'Delete',
                        role: 'destructive',
                        icon: 'trash',
                        handler: () => {
                            console.log('Delete clicked');
                        }
                    }
                ]
            });
            await actionSheet.present();
        }
    }

    async openProfileDetailModal(userId: string, isCurrentUser: boolean) {
        if (isCurrentUser) {
            const modal = await this.modalController.create({
                component: ModalMyProfileComponent,
                cssClass: 'modal-styles'
            });
            return await modal.present();
        } else {
            const c = await this.contactService.getContact(userId, null, true).pipe(take(1)).toPromise();
            const modal = await this.modalController.create({
                component: ModalContactDetailComponent,
                cssClass: 'modal-styles modal-contact-detail',
                componentProps: { contact: c }
            });
            return await modal.present();
        }
    }

    send() {
        if (this.textareaValue.trim()) {
            this.sendClicked.emit([this.textareaValue.trim(), this.reply]);
            this.textareaValue = '';
            this.reply = null;
            setTimeout(() => {
                this.MessengerAutoSize.resize();
            }, 0);
        }
    }

    submitOnEnterDesktop(event) {
        // const onEnter = this.platform.is('desktop') && (event.code === 'Enter' && !event.shiftKey);
        // const onNumpadEnter = this.platform.is('desktop') && (event.code === 'NumpadEnter' && !event.shiftKey);
        // if (onEnter || onNumpadEnter) {
        this.send();
        // }
    }

    leaveAtBottom(event) {
        console.log('leaveAtBottom', event);
        this.atBottom$.next(false);
    }

    atBottom(event) {
        this.atBottom$.next(event);
        console.log('atBottom', event);
    }

    trackByBlock(index, item) {
        return index;
    }

    trackByGroup(index, item) {
        return index;
    }

    trackByMsg(index, item) {
        return index;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.messageBlock?.isFirstChange()) {
            this.isFirstMessageBlockChange$.next();
        }
        this.checkScroll$.next();
    }

    ngAfterViewInit() {
        this.afterViewInit$.next();
    }

}
