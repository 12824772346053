import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import algoliasearch from 'algoliasearch';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, tap, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContactSearch } from '../models/request.model';
import { UserModel } from '../models/user.model';
import { BaseService } from './base.service';
import { ContextService } from './context.service';
import { wrapWithLoadingInformation } from './rxjs-helpers/common.helper';

@Injectable({ providedIn: 'root' })
export class PlaceService extends BaseService {
    private algoliaClient = algoliasearch(environment.algolia.appId, environment.algolia.searchApiKey).initIndex(environment.algolia.placesIndex);


    constructor(
        private contextService: ContextService,
        private af: AngularFireFunctions, private afs: AngularFirestore) {
        super(contextService);

    }

    getPlaces(queryText) {
        return this.af.httpsCallable('searchPlaces')({ queryText });
    }
    searchPublicRooms(query$: Observable<string>) {

        return query$.pipe(
            switchMap((query) => {
                return from(this.algoliaClient.search(query, {
                    hitsPerPage: 20,
                    attributesToHighlight: [],
                })).pipe(map(algoliaResult => algoliaResult.hits))
            })
        )
    }
    getCurrentUserRoomsId() {
        return this.afs.collectionGroup('participants', ref => ref.where('uid', '==', this.currentUserUid)).snapshotChanges().pipe(map(s => s.map(ss => ss.payload.doc.ref.parent.parent.id)));
    }
    joinRoom(roomId: string) {
        return this.afs.doc<UserModel>(`users/${this.currentUserUid}`).get().toPromise().then(user => {
            const { color, firstName, gender, lastName } = user.data();
            return this.afs.doc(`chat_rooms/${roomId}/participants/${this.currentUserUid}`).set({
                color, firstName, lastName, gender, uid: this.currentUserUid
            });

        })

    }
}