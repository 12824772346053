import { Component, Input } from '@angular/core';
import { UserModel } from '../../../models/user.model';
import { ModalContactDetailComponent } from '../../modals/modal-contact-detail/modal-contact-detail.component';
import { ModalController, ToastController } from '@ionic/angular';
import { ContactService } from 'src/app/services/contact.service';
import { ContactSearch, RequestStatus } from 'src/app/models/request.model';
import { TranslocoService } from '@ngneat/transloco';
import { ContactModel } from 'src/app/models/contact.model';
import { GlobalSearchResultItem, GlobalSearchResultType } from 'src/app/models/global-search.model';
import { take } from 'rxjs/operators';
import { PlaceService } from 'src/app/services/places.service';

@Component({
    selector: 'app-search-result-card',
    templateUrl: './search-result-card.component.html',
    styleUrls: ['./search-result-card.component.scss'],
})
export class SearchResultCardComponent {

    requestStatus = RequestStatus;
    type = GlobalSearchResultType;
    @Input() public result: GlobalSearchResultItem;
    // public working = false;

    constructor(
        private placeService: PlaceService,
        public modalController: ModalController,
        private contactService: ContactService,
        public toastController: ToastController,
        private translocoService: TranslocoService
    ) {
    }

    public async openContactDetailModal(contact: GlobalSearchResultItem) {
        const c = await this.contactService.getContact(contact.id).pipe(take(1)).toPromise();
        const modal = await this.modalController.create({
            component: ModalContactDetailComponent,
            cssClass: 'modal-styles modal-contact-detail',
            componentProps: { contact: c }
        });
        return await modal.present();
    }

    async statusInfoToast(text) {
        const toast = await this.toastController.create({
            message: text,
            duration: 4000
        });
        await toast.present();
    }

    async addContactRequest() {
        // this.working = true;
        await this.contactService.createContactRequest(this.result.id);
        this.result.status = RequestStatus.pending;
        // make the contact request
        await this.statusInfoToast(this.translocoService.translate('status.contact.request', {name: this.result.title}));
    }

    async joinRegionalRoom() {
        await this.placeService.joinRoom(this.result.id).then(() => this.result.status = RequestStatus.accepted);
        await this.statusInfoToast(this.translocoService.translate('status.regional_room.request', {name: this.result.title}));
    }
}
