import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

@Directive({selector: '[intersection-observer]'})
export class IntersectionObserverDirective implements AfterViewInit, OnDestroy {

    @Output() visible = new EventEmitter<boolean>();
    private intersectionObserver: IntersectionObserver;

    constructor(private _elemRef: ElementRef) {
    }

    ngOnDestroy() {
        this.intersectionObserver.unobserve(this._elemRef.nativeElement);
        this.intersectionObserver.disconnect();
    }

    ngAfterViewInit(): void {
        // Options for the observers
        let observerOptions = {
            root: null,
            rootMargin: '5px',
            threshold: [0.0, 0.1]
        };
        this.intersectionObserver = new IntersectionObserver(this.intersectionCallback, observerOptions);
        this.intersectionObserver.observe(this._elemRef.nativeElement);
    }

    intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            let visiblePct = (Math.floor(entry.intersectionRatio * 100));
            if (visiblePct > 0) {
                this.visible.emit(true);
            } else {
                this.visible.emit(false);
            }
            // console.log('intersectionCallback', visiblePct);
        });
    }

}
