import {Component, Input, ViewChild} from '@angular/core';
import {IonInput, ModalController} from '@ionic/angular';
import { Observable } from 'rxjs';
import { ContactModel } from 'src/app/models/contact.model';
import { LabelService } from 'src/app/services/label.service';


@Component({
    selector: 'app-modal-contact-labels-edit',
    templateUrl: './modal-contact-labels-edit.component.html',
    styleUrls: ['./modal-contact-labels-edit.component.scss'],
})
export class ModalContactLabelsEditComponent {

    @Input() contact: ContactModel;
    @ViewChild('input') input: IonInput;
    public labels$: Observable<string[]>;
    constructor(
        private modalCtrl: ModalController,
        private labelService: LabelService) {
        this.labels$ = this.labelService.getLabels();
    }

    public submit(input: string | number, value, closePopOver = false) {
        const v = (input as string).trim();
        if (v) {
            this.labelService.setLabel(v, this.contact, value)
                .then(() => {
                    if (!value) {
                        const index = this.contact.labels.indexOf(v, 0);
                        if (index > -1) {
                            this.contact.labels.splice(index, 1);
                        }
                    } else {
                        if (this.contact.labels.indexOf(v, 0) === -1)
                            this.contact.labels.push(v);
                    }
                })
                .then(() => closePopOver ? this.modalCtrl.dismiss() : null);
        }
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

}
