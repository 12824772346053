import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonSlides, ModalController, PopoverController } from '@ionic/angular';
import { MessengerService } from 'src/app/services/messenger.service';
import { FileService } from '../../../services/file.service';
import { ModalAddContactsComponent } from '../modal-add-contacts/modal-add-contacts.component';
import { ContactModel } from 'src/app/models/contact.model';
import { UserChatRoom } from 'src/app/models/messenger.model';
import { map, take, tap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { noWhitespaceValidator } from 'src/app/helpers/form-validators.helper';
import { deleteFieldValue } from 'src/app/helpers/firebase.helper';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'app-modal-room-info',
    templateUrl: './modal-room-info.component.html',
    styleUrls: ['./modal-room-info.component.scss']
})
export class ModalRoomInfoComponent implements OnInit {

    public isAdmin: boolean;
    public editMode: boolean;
    public mode: 'createRoom' | 'editRoom';
    public roomForm: FormGroup;
    public loading = false;
    public roomNameWorking: boolean;
    public roomNameUpdated: boolean;
    public optionsWorking = false;
    public usersList: ContactModel[];

    @Input() room: UserChatRoom;
    public uplodateStatus$: Observable<number>;

    @ViewChild('slides', { static: false }) slides: IonSlides;

    slideOpts = {
        // autoHeight: true,
        // initialSlide: 0,
        allowTouchMove: false
    };


    constructor(
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private fileService: FileService,
        private messengerService: MessengerService,
        public modalController: ModalController,
        public alertController: AlertController,
        public popoverController: PopoverController,
        private translocoService: TranslocoService
    ) {
        this.uplodateStatus$ = this.fileService.uploadStatus$;
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }
    async removeAvatar() {
        if (this.mode === 'editRoom') {
            await this.messengerService.updateRoomAvatar(deleteFieldValue, this.room.id);
            this.roomForm.controls.avatar.reset(null);
        } else {
            this.roomForm.controls.avatar.reset(null);
        }
    }
    async avatarSelected(file: File) {
        const r = await this.fileService.uploadRoomAvatar(file, this.roomForm) as any;
        if (this.mode === 'editRoom' && r) {
            await this.messengerService.updateRoomAvatar(r.avatar, this.room.id);
        }
    }
    async updateRoomName() {
        this.roomNameUpdated = false;
        this.roomNameWorking = true;
        await this.messengerService.updateRoomName(this.roomForm.controls.roomName.value, this.room.id).then(() => timer(2500).toPromise());
        this.roomName.markAsPristine();
        this.roomNameWorking = false;
        this.roomNameUpdated = true;
    }
    async createRoom() {
        try {
            this.loading = true;
            await this.messengerService.createGroupRoom(this.roomForm.value, !!!this.room, this.usersList);
            this.loading = false;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
            this.dismissModal();
        }
    }

    async addContacts() {
        const modal = await this.modalController.create({
            component: ModalAddContactsComponent,
            cssClass: 'modal-styles modal-add-contacts',
            componentProps: {
                selectedUsers: this.usersList
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result.data?.contacts) {
            const contacts = result.data.contacts.filter(a => a.isSelected);
            this.usersList = (this.usersList || []).concat(contacts);
            if (this.mode === 'editRoom') {
                await this.messengerService.addContactsToRoom(contacts, this.room.id);
            }
        }
    }

    async leaveRoom() {
        if (this.isAdmin) {
            const alert = await this.alertController.create({
                cssClass: 'modal-styles',
                header: this.translocoService.translate('rooms.errors.leave.choose_new_admin.title'),
                message: this.translocoService.translate('rooms.errors.leave.choose_new_admin.text'),
                buttons: [this.translocoService.translate('common.understood')]
            });
            await alert.present();
        } else {
            const alert = await this.alertController.create({
                cssClass: 'modal-styles',
                header: this.translocoService.translate('rooms.errors.leave.confirm.title'),
                message: this.translocoService.translate('rooms.errors.leave.confirm.text'),
                buttons: [
                    {
                        text: this.translocoService.translate('common.cancel'),
                        handler: () => { } // don't do anything if cancel
                    },
                    {
                        text: this.translocoService.translate('common.ok'),
                        handler: async () => {
                            // leave group and close modal
                            await this.messengerService.removeUserFromRoom(null, this.room.id);
                            this.dismissModal();
                        }
                    }
                ]
            });
            await alert.present();
        }
    }

    async roomMemberOptions(event: any, user: ContactModel) {
        const popover = await this.popoverController.create({
            component: RoomMemberOptionsListComponent,
            componentProps: {
                displayAdminButton: this.mode === 'editRoom'
            },
            event: event,
        });
        await popover.present();

        const { data } = await popover.onDidDismiss() as { data?: { action: 'appointAsNewAdmin' | 'removeMember' } };
        if (data?.action === 'appointAsNewAdmin') {
            if (this.mode === 'createRoom') {
                // not possible
            } else if (this.mode === 'editRoom') {
                // set the new admin in the DB
                this.optionsWorking = true;
                await this.messengerService.setAdminForRoom(user.uid, this.room.id);
                const currentUser = this.usersList.filter(a => a.uid === this.messengerService.currentUserId)[0];
                currentUser.isAdmin = false;
                user.isAdmin = true;
                this.isAdmin = false;
                this.editMode = false
            }
        } else if (data?.action === 'removeMember') {
            if (this.mode === 'createRoom') {
                // remove the user from the list no action in the db
                this.optionsWorking = true;
                this.usersList = this.usersList.filter(a => a.uid !== user.uid);
                this.optionsWorking = false
            } else if (this.mode === 'editRoom') {
                // remove the user from the DB and the list ?!
                this.optionsWorking = true;
                this.messengerService.removeUserFromRoom(user.uid, this.room.id).then(() => {
                    this.usersList = this.usersList.filter(a => a.uid !== user.uid);
                    this.optionsWorking = false
                })
            }
        }
    }

    async slideNext() {
        await this.slides.slideNext();
    }

    async slidePrev() {
        await this.slides.slidePrev();
    }

    ngOnInit() {
        this.mode = this.room?.id ? 'editRoom' : 'createRoom';
        this.isAdmin = this.room?.createdBy === this.messengerService.currentUserId || this.mode === 'createRoom';
        this.editMode = this.isAdmin;

        this.roomForm = this.formBuilder.group({
            editMode: [!!this.room?.id],
            id: [this.room?.id || this.messengerService.newRoomId],
            avatar: [this.room?.avatar],
            roomName: [this.room?.title, [
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(32),
                noWhitespaceValidator(6)
            ]]
        });
        if (this.room)
            this.messengerService.getRoomParticipants(this.room.id).pipe(
                take(1),
                map(participants => participants.map(a => new Object({ ...a, isAdmin: a.id === this.room?.createdBy }) as (ContactModel & { id: string; })))
            ).subscribe((participants) => this.usersList = (this.usersList || []).concat(participants))
    }

    get roomName() {
        return this.roomForm.get('roomName');
    }
    get avatar() {
        return this.roomForm.get('avatar').value;
    }
}



@Component({
    template: `
        <ion-list>
            <ion-item *ngIf="displayAdminButton" button (click)="appointAsNewAdmin();">
                <ion-label>{{ 'rooms.change_admin' | transloco }}</ion-label>
            </ion-item>
            <ion-item button (click)="removeMember();">
                <ion-label>{{ 'rooms.kick_user' | transloco }}</ion-label>
            </ion-item>
        </ion-list>
    `,
})
export class RoomMemberOptionsListComponent {

    @Input() displayAdminButton: boolean;

    constructor(public popoverController: PopoverController) {
    }

    appointAsNewAdmin() {
        this.popoverController.dismiss({ action: 'appointAsNewAdmin' });
    }

    removeMember() {
        this.popoverController.dismiss({ action: 'removeMember' });
    }

    public closePopover() {
        this.popoverController.dismiss();
    }

}
