import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'srcSet'
})

export class SrcSetPipe implements PipeTransform {
    transform(value: any, variants: any, sizes: any): any {


        if (!!!value || !!!value.sizes) { return null; }

        const result = [];
        for (let index = 0; index < variants.length; index++) {
            const variant = variants[index];
            const size = sizes[index];
            const src = value.sizes[variant];
            if (src) {
                const url = `${src} ${size}`;
                result.push(url);
            }
        }


        return result.join(', ');
    }
}