import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';

import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { ChatRoom, Participant, UserChatRoom } from 'src/app/models/messenger.model';

export const sortRooms = map((r: UserChatRoom[]) => r.sort((a1, a2) =>
    a2.lastMessage.createdAt && a1.lastMessage.createdAt ?
        (a2.lastMessage.createdAt as Timestamp).seconds - (a1.lastMessage.createdAt as Timestamp).seconds :
        -1));





export const getRoomFromUserParticipation = map((refs: DocumentChangeAction<Participant>[]) => {
    return refs.map(r => r.payload.doc.ref.parent.parent.id);
});

export const getParticipationFromCurrentUser = (currentUserUid: Observable<string>, afs: AngularFirestore) => currentUserUid
    .pipe(
        switchMap(
            (userId: string) =>
                afs.collectionGroup<Participant>('participants', ref => ref
                    .where('uid', '==', userId))
                    .snapshotChanges())
    );

export const combineRoomsAndParticipants = (afs: AngularFirestore) => switchMap(<T>(snapshots: DocumentChangeAction<T>[]) => {
    // foreach participation of the user in any rooms (rooms_chat and rooms_group)
    const roomsWithParticipants = snapshots.map(a => {
        const participants = afs.collection<Participant>(a.payload.doc.ref.parent.path).valueChanges().pipe(retry(1));
        const room = afs.doc<ChatRoom>(a.payload.doc.ref.parent.parent.path).valueChanges({ idField: 'id' }).pipe(retry(1));
        return combineLatest([participants, room]);
    })
    return combineLatest(roomsWithParticipants);
});

export const mapToChatRoom = (currentUserId: string) => map((a: [Participant[], ChatRoom & { id: string; }][]) => {
    return a.map((b) => { return new UserChatRoom(b, currentUserId) }) || [];
})

export const getUserRooms = (afs: AngularFirestore, userId: string) => afs.collectionGroup<Participant>('participants', query => query.where('uid', '==', userId)).snapshotChanges()