import { Pipe, PipeTransform } from '@angular/core';
import { ContactModel } from 'src/app/models/contact.model';
import { ContextService } from 'src/app/services/context.service';

@Pipe({ name: 'usersListSort' })
export class UsersListSortPipe implements PipeTransform {
    constructor(private contextService: ContextService) { }
    transform(value: ContactModel[], column: string = ''): any[] {
        const currentUserId = this.contextService.authState.uid;

        if (!value) { return value; } // no array
        if (value.length <= 1) { return value; } // array with only one item
        return value.sort((a, b) => {
            if (a.uid === currentUserId) return -1;
            if (b.uid === currentUserId) return 1
            if (a.isAdmin) return -1;
            if (b.isAdmin) return 1;
            return a.firstName.localeCompare(b.firstName);
        })
    }
}