import {Injectable, RendererFactory2} from '@angular/core';
// TODO Check sometimes when the bug in compiler is fixed. then we can move Renderer2 again to above import
// More about it: https://github.com/ng-packagr/ng-packagr/issues/1543#issuecomment-593873874
import {Renderer2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContrastService {

    private renderer: Renderer2;
    private contrastLevel = 'normal';
    // Define prefix for more clear and readable styling classes in scss files
    private contrastLevelPrefix = 'contrast-';

    constructor(rendererFactory: RendererFactory2) {
        // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    _setContrastLevel(level) {
        this.contrastLevel = level;
        // Save prefers-color-scheme to localStorage
        localStorage.setItem('contrast', level);
    }

    _getContrastLevel() {
        // Check if any prefers-color-scheme is stored in localStorage
        if (localStorage.getItem('contrast')) {
            // Save prefers-color-scheme from localStorage
            this.contrastLevel = localStorage.getItem('contrast');
        }
    }

    load() {
        this._getContrastLevel();
        this.renderer.addClass(document.body, this.contrastLevelPrefix + this.contrastLevel);
    }

    update(level) {
        this._setContrastLevel(level);
        // Remove all old class from body
        this.removeOldClass(this.contrastLevelPrefix);
        // Remove the old color-scheme class
        // this.renderer.removeClass(document.body, this.colorSchemePrefix + (this.primaryColor === 'dark' ? 'light' : 'dark'));
        // Add the new / current color-scheme class
        this.renderer.addClass(document.body, this.contrastLevelPrefix + level);
    }

    removeOldClass(prefix) {
        for (let i = document.body.classList.length - 1; i >= 0; i--) {
            if (document.body.classList[i].startsWith(prefix)) {
                document.body.classList.remove(document.body.classList[i]);
            }
        }
    }

    currentActive() {
        return this.contrastLevel;
    }

}
