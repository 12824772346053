import { Component, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
    selector: 'app-layout-default-page',
    templateUrl: './layout-default-page.component.html',
    styleUrls: ['./layout-default-page.component.scss'],
})
export class LayoutDefaultPageComponent implements OnDestroy {

    constructor(private afMessaging: AngularFireMessaging) {
        this.requestPermission();
    }
    requestPermission() {
        // this.afMessaging.requestPermission.subscribe();
        // this.afMessaging.requestToken
        //     .subscribe(
        //         (token) => { console.log('Permission granted! Save to the server!', token); },
        //         (error) => { console.error(error); },
        //     );

    }
    ngOnDestroy() { }

}
