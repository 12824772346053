import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findInArray',
})
export class FindInArrayPipe implements PipeTransform {
    transform(val: Array<any>, element: any): any {
        return val.find(a => a == element);
    }
}
