import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserModel } from '../../../models/user.model';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { nameValidators } from 'src/app/helpers/fields.helper';
import { FileService } from 'src/app/services/file.service';
import { deleteFieldValue } from 'src/app/helpers/firebase.helper';

@Component({
    selector: 'app-modal-my-profile',
    templateUrl: './modal-my-profile.component.html',
    styleUrls: ['./modal-my-profile.component.scss'],
})
export class ModalMyProfileComponent {

    currentUser$: Observable<UserModel>;

    public favourite = false;
    public loading = false;
    public updateProfileForm: FormGroup;
    public uploadProgress: Observable<number>;
    constructor(
        private fileService: FileService,
        private modalCtrl: ModalController,
        private userService: UserService,
        private formBuilder: FormBuilder
    ) {
        this.uploadProgress = fileService.uploadStatus$;
        this.currentUser$ = userService.user$.pipe(tap((user) => this.updateProfileForm.patchValue(user)));
        this.updateProfileForm = this.formBuilder.group({
            firstName: [null, nameValidators],
            lastName: [null, nameValidators],
            phoneNumber: [null, [
                Validators.pattern('[- +()0-9]+')
            ]],
            mobileNumber: [null, [
                Validators.pattern('[- +()0-9]+')
            ]],
            profile: ['public']
            // password: [null, [
            //     // Validators.required,
            //     Validators.minLength(6),
            //     Validators.maxLength(32)
            // ]]
        });
    }
    avatarSelected(file: File) {
        this.fileService.uploadAvatar(file);
    }

    deleteAvatar() {
        this.userService.updateUser({ avatar: deleteFieldValue });
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    async updateProfile() {
        try {
            this.loading = true;
            await this.userService.updateUser(this.updateProfileForm.value);
            this.loading = false;
        } catch (e) {
            /*const toast = await this.toastController.create({
                message: this.translocoService.translate('error.firebase.' + e.code),
                color: 'danger',
                duration: environment.ionicComponentConfig.toast.duration
            });
            await toast.present();*/
        } finally {
            this.loading = false;
        }
    }

    get firstName() {
        return this.updateProfileForm.get('firstName');
    }

    get lastName() {
        return this.updateProfileForm.get('lastName');
    }

    get phoneNumber() {
        return this.updateProfileForm.get('phoneNumber');
    }

    get mobileNumber() {
        return this.updateProfileForm.get('mobileNumber');
    }

}
