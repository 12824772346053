import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'equals',
})
export class EqualsPipe implements PipeTransform {
    transform(val: any, element: any): any {
        return val === element;
    }
}
