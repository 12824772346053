import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { of } from 'rxjs';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private angularFirestore: AngularFirestore,
        private angularFireAuth: AngularFireAuth) {
    }

    public updateUser(data: any) {
        return this.userUid.then((user) => {
            if (user && user.uid) {
                return this.angularFirestore.doc<UserModel>(`users/${user.uid}`).set({ ...data, email: user.email }, { merge: true });
            } else {
                return Promise.reject('user not logged in');
            }
        });
    }

    public async updatePassword(password) {
        await (await this.angularFireAuth.currentUser).updatePassword(password);
    }

    public async deleteUser() {
        await (await this.angularFireAuth.currentUser).delete;
    }

    public async signOut() {
        await this.angularFireAuth.signOut();
    }

    /*    public getUser(userId?: string) {
            if (!!!userId) {
                return this.auth.authenticated.pipe(
                    switchMap((auth) => !!!auth ? of(null as UserModel) : this.angularFirestore.doc<UserModel>(`users/${auth.uid}`).valueChanges()),
                    shareReplay()
                );
            }
    
            return this.angularFirestore.doc<UserModel>(`users/${userId}`).valueChanges().pipe(shareReplay());
        }*/

    public getCurrentUser() {
        return this.angularFireAuth.currentUser;
    }

    public get userUid() {
        return this.angularFireAuth.currentUser;
    }

    public get user$() {
        return this.angularFireAuth.authState
            .pipe(
                switchMap(user => {
                    if (user) {
                        return this.angularFirestore.doc<UserModel>(`users/${user.uid}`).valueChanges();
                    } else {
                        return of(null as UserModel); // I had to modify this in order to get ride of default value in the html
                    }
                }),
                shareReplay());
    }
}
