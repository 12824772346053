import {Directive, ElementRef, Renderer2} from '@angular/core';
import {Platform} from '@ionic/angular';

@Directive({selector: '[scroll-bar-styles]'})
export class IonContentScrollBarStylesDirective {

    constructor(private _elemRef: ElementRef, private renderer: Renderer2, private platform: Platform) {

        if (this.platform.is('desktop')) {
            const content = document.querySelector('ion-content');
            const styles = document.createElement('style');

            styles.textContent = `
            ::-webkit-scrollbar {
                width: 3px;
                height: 3px;
            }
            ::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.24);
                border-radius: 0;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: rgba(var(--ion-color-primary-rgb), 1);
            }
            ::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 10px;
            }
            `;

            this.renderer.appendChild(this._elemRef.nativeElement.shadowRoot, styles);
        }

    }

}
