import { RequestStatus } from './request.model';
import { Gender } from './user.model';

export interface GlobalSearchAlgolia {
    group: boolean;
    person: boolean;
    firstName?: string;
    lastName?: string;
    livingTown?: string;
    id: string;
    placeRef?: string;
    avatar?: any;
    gender: Gender;
}

export interface IGlobalSerachResult {
    empty: boolean;
    queryEmpty: boolean;
    persons: any[];
    groups: any[];
    query: string;
}

export class GlobalSearchResult implements IGlobalSerachResult {
    queryEmpty: boolean;
    query: string;
    empty: boolean;
    persons: GlobalSearchResultItem[];
    groups: GlobalSearchResultItem[];

    constructor(
        queryEmpty = false,
        query: string = null,
        persons = null,
        groups = null
    ) {
        this.query = query;
        this.queryEmpty = queryEmpty;
        this.persons = persons;
        this.groups = groups;
        this.empty = !this.hasAnyGroup() && !this.hasAnyPerson();
    }

    public hasAnyPerson() {
        return this.persons?.length > 0;
    }

    public hasAnyGroup() {
        return this.groups?.length > 0;
    }

}

export interface IGlobalSearchResultItem {
    avatar?: any;
    title: string;
    description?: string;
    type: GlobalSearchResultType;
}

export class GlobalSearchResultItem implements IGlobalSearchResultItem {
    id: string;
    avatar?: any;
    title: string;
    description?: string;
    type: GlobalSearchResultType;
    status: RequestStatus;
    gender: Gender;
    constructor(item: GlobalSearchAlgolia, status = null) {
        this.id = item.id;
        this.status = status;
        if (item.group === true && item.person === false) {
            this.type = GlobalSearchResultType.regionalRoom;
            this.title = item.placeRef;

        } else if (item.group === false && item.person === true) {
            this.type = GlobalSearchResultType.person;
            this.title = `${item.firstName} ${item.lastName}`;
            this.description = item.livingTown;
            this.avatar = item.avatar;
            this.gender = item.gender;

        }
    }
}

export enum GlobalSearchResultType {
    person = 0, regionalRoom = 1
}
