import { Timestamp, FieldValue } from '@firebase/firestore-types';
export interface NewMessage {
    userId: string;
    chatName: string;
    messages: MessengerMessageInterface[];
}

export interface Participant {
    firstName: string;
    lastName: string;
    gender: string;
    color: string;
    uid: string;
    lastRead: Timestamp;
    unreadCount: number;
    avatar: any;
}
export interface Message {
    message: string;
    createdBy: string;
    createdAt: Timestamp | FieldValue;
    reply?: any;
}

export interface ChatRoom {
    placeRef?: string;
    type: RoomType;
    lastMessage?: Message;
    title: string;
    createdBy: string;
    avatar: any;
}

export interface MessageWithUser extends Message {
    createdByUser: Participant;
}
export interface IUserChatRoom {
    placeRef?: string;
    public: boolean;
    type: RoomType;
    lastMessage: MessageWithUser;
    id: string;
    count: number;
}
export class UserChatRoom implements IUserChatRoom {
    lastMessage: MessageWithUser;
    type: RoomType;
    id: string;
    title: string;
    placeRef: string;
    public: boolean;
    count: number;
    user: any;
    createdBy: string;
    avatar: any;
    isAdmin: boolean;
    constructor(data: [Participant[], ChatRoom & { id: string; }], currentUserId: string) {
        const room = data[1];
        const users = data[0];
        this.isAdmin = room.createdBy === currentUserId;
        this.createdBy = room.createdBy;
        this.placeRef = room.placeRef;
        this.type = room.type;
        this.title = mapTitle(room, users, currentUserId);
        this.id = room.id;
        this.user = users.find(a => a.uid !== currentUserId);
        this.count = users.find(a => a.uid === currentUserId)?.unreadCount;
        this.avatar = room.avatar;
        this.lastMessage = { ...room.lastMessage, createdByUser: users.find(a => a.uid === room.lastMessage?.createdBy) };
    }
}
const mapTitle = (room: ChatRoom & { id: string; }, users: Participant[], currentUserId: string) => {
    switch (room.type) {
        case RoomType.oneToOne:
            const otherUser = users.find(a => a.uid !== currentUserId);
            return otherUser ? `${otherUser.firstName} ${otherUser.lastName}` : null;
        case RoomType.regionGroup:
            return room.placeRef;
        case RoomType.personGroup:
            return room.title;
        default:
            return null;
    }
}
export interface MessengerMessageBlockInterface {
    date: Date;
    messageGroup: MessengerMessageGroupInterface[];
}

export interface MessengerMessageGroupInterface {
    type: string;
    user_id?: any;
    user_name?: string;
    user_avatar?: AvatarInterface;
    messages?: MessengerMessageInterface[];
}

export interface AvatarInterface {
    preview?: string;
    src?: string;
    srcset?: string;
    default?: string;
}

export interface MessengerMessageInterface {
    created_at?: Date;
    text: string;
    reply?: MessengerReplyInterface;
    user_name: string;
}

export interface MessengerReplyInterface {
    user_name: string;
    text: string;
    color?: string;
}

export enum RoomType {
    oneToOne = 0,
    regionGroup = 1,
    personGroup = 2
}
