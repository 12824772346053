import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface ObsWithStatusResult<T> {
    loading?: boolean;
    emptyArray?: boolean;
    emptyValue?: boolean;
    value?: any;
}

const defaultError = 'Something went wrong';

@Pipe({
    name: 'messageCount',
})
export class MessageCountPipe implements PipeTransform {
    transform(val: any): number {
        return 5;
    }
}
