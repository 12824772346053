import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';



@Pipe({
    name: 'notificationDateGroup',
})
export class NotificationDateGroupPipe implements PipeTransform {
    constructor(private transloco: TranslocoService) { }
    transform(evenDay: number): string {
        if (evenDay === 0) return this.transloco.translate('date.today');
        if (evenDay > 0) return this.transloco.translate('date.older');
    }
}
