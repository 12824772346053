import { Timestamp } from '@firebase/firestore-types';
import { UserModel } from './user.model';

export interface Call {
    callId: string;
    caller: UserModel;
    receiver: UserModel;
    status: CallStatus;
}
export interface CallDataBase {
    callId: string;
    callerId: string;
    calledAt: Timestamp;
    status: CallStatus;
}
export enum CallStatus {
    calling = 0, receiving = 1, busy = 2, accepted = 3, declined = 4, cancelled = 5
}