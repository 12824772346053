import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ContactRequest, DataBaseContactRequestExtended } from 'src/app/models/request.model';
import { ContactService } from 'src/app/services/contact.service';
import { ObsWithStatusResult } from 'src/app/services/rxjs-helpers/common.helper';

@Component({
    selector: 'app-modal-notifications',
    templateUrl: './modal-notifications.component.html',
    styleUrls: ['./modal-notifications.component.scss']
})
export class ModalNotificationsComponent implements OnInit {
    public requests$: Observable<ObsWithStatusResult<ContactRequest[]>>;

    constructor(private modalCtrl: ModalController, private contactService: ContactService) {
        this.requests$ = this.contactService.getContactRequests();
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    segmentChanged(ev: any) {
    }

    decline(request: DataBaseContactRequestExtended) {
        request.isWorking = true;
        this.contactService.rejectContactRequest(request.requestedBy).then(() => request.isComplete = request.isDeclined = true);
    }

    accept(request: DataBaseContactRequestExtended) {
        request.isWorking = true;
        this.contactService.acceptContactRequest(request.requestedBy).then(() => request.isComplete = request.isAccepted = true);
    }

    ngOnInit() {
    }

    public trackByEvenDay = (index: number, item: any) => item.evenDay;
    public trackByUserId = (index: number, item: any) => item.uid;
}
