import { AngularFirestore, DocumentChangeAction } from "@angular/fire/firestore";
import { combineLatest, Observable, of } from "rxjs";
import { watch } from "rxjs-watcher";
import { defaultIfEmpty, delay, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { UserModel } from "src/app/models/user.model";
import { getUserContactRequests } from "./contact.helper";



export interface ObsWithStatusResult<T> {
    loading?: boolean;
    emptyArray?: boolean;
    emptyValue?: boolean;
    value?: T;
}

export const wrapWithLoadingInformation = <T>(val: Observable<T>): Observable<ObsWithStatusResult<T>> => {
    return val.pipe(
        map((value: any) => {
            return {
                loading: false,
                value: value as any,
                emptyArray: value?.length === 0,
                emptyValue: value === null
            };
        }),
        startWith({ loading: true }),
    );
}


export const joinWithUser = <T>(angularFirestore: AngularFirestore, field: string, fieldToMap?: string) => switchMap((request: T) => {
    const userId = request[field];
    const user = getContact(userId, angularFirestore).pipe(defaultIfEmpty(null as UserModel));
    return combineLatest([of(request), user]).pipe(map(([request, user]) => {
        request[fieldToMap || field] = user;
        return request as T;
    }));
})

export const joinWithUsers = <T>(angularFirestore: AngularFirestore, field: string) => switchMap((requests: T[]) => {
    const userIds = requests.map(r => r[field]);
    const users = combineLatest(userIds.map(userId => getContact(userId, angularFirestore))).pipe(defaultIfEmpty(null as UserModel[]));
    return combineLatest([of(requests), users]);
})

const getContact = (userId: string, afs: AngularFirestore) => {

    return afs.collection('users').doc<UserModel>(userId).valueChanges().pipe(map((data: any) => new Object({
        ...data,
        uid: userId
    }) as UserModel)
    );
}

export const log = <T>(message) => tap<T>(v => console.log(message, v));

export const filterCachedAndPendingWritesRecords = map(<T>(snaps: DocumentChangeAction<T>[]) => snaps.filter(a => !a.payload.doc.metadata.fromCache && !a.payload.doc.metadata.hasPendingWrites))
