import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-modal-messenger-group-placement-info',
    templateUrl: './modal-messenger-group-placement-info.component.html',
    styleUrls: ['./modal-messenger-group-placement-info.component.scss'],
})
export class ModalMessengerGroupPlacementInfoComponent implements OnInit {
    @Input() locality: string;
    @Input() subLocality: string;
    @Input() userName: string;
    constructor(private modalCtrl: ModalController) {
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    ngOnInit() {
    }

}
