import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { ZoomService } from '../../services/zoom.service';
import { ContrastService } from '../../services/contrast.service';
import { Platform } from '@angular/cdk/platform';
import { MenuController, ModalController, PopoverController } from '@ionic/angular';
import { ContactService } from '../../services/contact.service';
import { ModalContactRequestNotificationsComponent } from '../../shared/modals/modal-contact-request-notifications/modal-contact-request-notifications.component';
import { ModalNotificationsComponent } from '../../shared/modals/modal-notifications/modal-notifications.component';
import { UserMenuComponent } from '../../shared/components/user-menu/user-menu.component';
import { ModalIncomingCallComponent } from '../../shared/modals/modal-incoming-call/modal-incoming-call.component';
import { Subject } from 'rxjs';
import { CallService } from 'src/app/services/call.service';
import { takeUntil } from 'rxjs/operators';
import { Call, CallStatus } from 'src/app/models/call.model';
import { ModalMessengerGroupPlacementInfoComponent } from 'src/app/shared/modals/modal-messenger-group-placement-info/modal-messenger-group-placement-info.component';
import { ModalCallComponent } from 'src/app/shared/modals/modal-call/modal-call.component';
import { SystemNotificationService } from 'src/app/services/system-notification.service';
import { ContextService } from 'src/app/services/context.service';
import { UserModel } from 'src/app/models/user.model';
import { MessengerService } from 'src/app/services/messenger.service';

@Component({
    selector: 'app-layout-default',
    templateUrl: './layout-default.component.html',
    styleUrls: ['./layout-default.component.scss'],
})
export class LayoutDefaultComponent implements OnDestroy {

    private ngUnsubscribe = new Subject();
    public currentUser: Observable<UserModel>;
    public requestNumber: Observable<number>;
    public totalUnreadCount: Observable<number>;

    mainMenu = [
        {
            title: 'common.dashboard',
            icon: '/assets/icons/feather/home.svg',
            href: null,
            routerLink: '/home'
        },
        {
            title: 'common.contacts',
            icon: '/assets/icons/feather/users.svg',
            href: null,
            routerLink: '/contacts'
        }
    ];

    bottomMenu = [
        {
            title: 'common.legal_notice',
            icon: '/assets/icons/feather/file-text.svg',
            url: '/legal-notice'
        },
        {
            title: 'common.privacy',
            icon: '/assets/icons/feather/file-text.svg',
            url: '/privacy-policy'
        },
        {
            title: 'common.settings',
            icon: '/assets/icons/feather/settings.svg',
            url: '/settings'
        }
    ];

    constructor(
        private messengerService: MessengerService,
        private contextService: ContextService,
        protected userService: UserService,
        private zoomService: ZoomService,
        private contrastService: ContrastService,
        public platform: Platform,
        private menu: MenuController,
        public modalController: ModalController,
        private contactService: ContactService,
        public popoverController: PopoverController,
        private callService: CallService,
        private systemNotificationService: SystemNotificationService
    ) {
        this.totalUnreadCount = this.messengerService.unreadTotalCount;
        this.requestNumber = this.contactService.getNumberOfContactRequests();
        this.currentUser = this.userService.user$;
        this.systemNotificationService.init().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        this.callService.getIncomingCalls().pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (call: Call) => {
            call.status = CallStatus.receiving;
            const top = await this.modalController.getTop();
            if (top) {
                await this.callService.setCallStatus(call, CallStatus.busy);
                return;
            }
            const modal = await this.modalController.create({
                component: ModalIncomingCallComponent,
                componentProps: { call },
                cssClass: 'modal-styles incoming-call',
                backdropDismiss: false
            });
            modal.onWillDismiss().then(result => {
                if (result.data.status) {
                    this.callService.setCallStatus(call, result.data.status as CallStatus);
                }
                if (result.data.roomId) {
                    this.callAccepted(result.data.roomId);
                }
            });
            await modal.present();

        });
    }

    async callAccepted(roomId) {
        const modal = await this.modalController.create({
            component: ModalCallComponent,
            componentProps: { roomId },
            cssClass: 'modal-styles call',
            backdropDismiss: false
        });
        await modal.present();
    }

    async openModalContactRequestNotifications() {
        const modal = await this.modalController.create({
            component: ModalContactRequestNotificationsComponent,
            cssClass: 'modal-styles contact-requests'
        });
        return await modal.present();
    }

    async openModalMessengerGroupPlacementInfo() {
        const modal = await this.modalController.create({
            component: ModalMessengerGroupPlacementInfoComponent,
            cssClass: 'modal-styles messenger-group-placement-info',
            backdropDismiss: false
        });
        return await modal.present();
    }

    async openModalNotifications() {
        const modal = await this.modalController.create({
            component: ModalNotificationsComponent,
            cssClass: 'modal-styles notifications auto-height'
        });
        return await modal.present();
    }

    async toggleMenu(id) {
        await this.menu.toggle(id);
    }

    async openUserMenu(ev: any) {
        const popover = await this.popoverController.create({
            component: UserMenuComponent,
            // cssClass: 'my-custom-class',
            event: ev,
            // translucent: true
        });
        return await popover.present();
    }

    changeZoomLevel() {
        this.zoomService.currentActive() === '1' ? this.zoomService.update('2') : this.zoomService.update('1');
    }

    changeContrast() {
        this.contrastService.currentActive() === 'normal' ? this.contrastService.update('high') : this.contrastService.update('normal');
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
