import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {of, Subject, timer} from 'rxjs';
import {Observable} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {Call, CallStatus} from 'src/app/models/call.model';
import {CallService} from 'src/app/services/call.service';
import {MessengerService} from 'src/app/services/messenger.service';

@Component({
    selector: 'app-modal-incoming-call',
    templateUrl: './modal-incoming-call.component.html',
    styleUrls: ['./modal-incoming-call.component.scss'],
})
export class ModalIncomingCallComponent {

    public callStatus = CallStatus;
    public call$: Observable<Call>;
    public status$: Observable<CallStatus>;
    public receiverStatus;
    private _call: Call;
    private _callStatus: CallStatus;
    private ngUnsubscribe = new Subject();

    @Input() set call(value: Call) {
        this._call = value;
        this._callStatus = value.status;

        this.call$ = of(value).pipe(
            takeUntil(this.ngUnsubscribe)
        );
        this.callService.getCallStatus(value.receiver.uid, value.callId).pipe(
            tap(status => this._callStatus = status),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(this.handleStatusChanges);
    }

    constructor(
        private messengerService: MessengerService,
        private modalCtrl: ModalController,
        private callService: CallService) {
            
    }

    dismiss(status: CallStatus, callAccepted = false) {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (this._callStatus === CallStatus.busy && status === CallStatus.cancelled) {
            status = null;
        }
        let roomId;
        if (callAccepted) {
            const callerId = this._call.caller.uid;
            const receiverId = this._call.receiver.uid;
            roomId = this.messengerService.getChatRoomOneToOneId(callerId, receiverId);
        }
        this.modalCtrl.dismiss({status, roomId});
    }

    handleStatusChanges = (status: CallStatus) => {
        switch (status) {
            case CallStatus.busy:
                this.receiverStatus = 'User is busy';
                break;
            case CallStatus.cancelled:
                this.dismiss(null);
                break;
            case CallStatus.accepted:
                this.dismiss(null, true);
                break;
            case CallStatus.declined:
                this.receiverStatus = 'User declined';
                timer(500).subscribe(() => this.dismiss(null));
                break;
            default:
                break;
        }
    }

    

}
