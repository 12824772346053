export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: "AIzaSyCCKVzQ8vo44ZBSTFRsjQzis9tVi1oZAWU",
        authDomain: "schlaue-buerger.firebaseapp.com",
        projectId: "schlaue-buerger",
        storageBucket: "schlaue-buerger.appspot.com",
        messagingSenderId: "404507957519",
        appId: "1:404507957519:web:e8ba664b8187a7844f467a",
        useEmulators: false
    },
    ionicComponentConfig: {
        toast: {
            duration: 4000
        }
    },
    algolia: {
        appId: 'OZZ892JZWB',
        searchApiKey: 'faad96ca8421e774b8f4631326e38026',
        globalIndex: 'schlaue_buerger_global_dev',
        placesIndex: 'schlaue_buerger_places_dev'
    },
    jitsi: {
        domain: 'vc.kiwitalk.de'
    },
    directus: {
        url: 'https://directus.buerger.io',
        publicToken: 'Bearer d1b77fc9-81fc-44a5-92ab-050c614924c1'
    }
};
