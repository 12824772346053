import { map } from "rxjs/operators";
import { ContactGroup, ContactModel } from "src/app/models/contact.model";

export const filterFromQueryWithSelectedUsers = map(([query, contacts, label, selectedUsers]: [string, ContactModel[], string, ContactModel[]]) => {
    if (!!!contacts || contacts.length === 0) return [];
    let result = contacts;
    if (query)
        result = result.filter((str) => {
            return str.firstName.toLowerCase().includes(query.toLowerCase())
                || str.lastName.toLowerCase().includes(query.toLowerCase());
        });
    if (label && label !== 'Alle') {
        result = result.filter((c) => c.labels.indexOf(label) > -1);
    }
    if (selectedUsers)
        result = result.filter((r) => selectedUsers.findIndex(rr => rr.uid === r.uid) === -1);
    return result;

});

export const filterFromQuery = map(([query, contacts, label]: [string, ContactModel[], string]) => {
    if (!!!contacts || contacts.length === 0) return [];
    let result = contacts;
    if (query)
        result = result.filter((str) => {
            return str.firstName.toLowerCase().includes(query.toLowerCase())
                || str.lastName.toLowerCase().includes(query.toLowerCase());
        });
    if (label && label !== 'Alle') {
        result = result.filter((c) => c.labels.indexOf(label) > -1);
    }
    return result;

});

export const mapToFirstNameLetter = map((source: ContactModel[]) => {
    const result: ContactGroup[] = [];
    if (!!!source || source.length === 0) return result;
    source.sort((a, b) => {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
        return 0;
    })
    const totalOfFavourites = source.filter(s => s.favourite === true).length;
    // group by favourite 
    for (const contact of source.filter(s => s.favourite === true)) {
        const firstChar = null;
        const group = result.find(g => g.isFavourite === true) || { groupKey: firstChar, contacts: [], isFavourite: true, displayHeader: true, count: totalOfFavourites };
        group.contacts.push(contact);
        if (group.contacts.length === 1) result.push(group);
    }


    // group by first letter
    let displayHeader = true;
    const totalOfContacts = source.filter(s => s.favourite === false).length;
    for (const contact of source.filter(s => s.favourite === false)) {
        const firstChar = contact.firstName.charAt(0).toUpperCase();
        const group = result.find(g => g.groupKey === firstChar) || { groupKey: firstChar, contacts: [], isFavourite: false, displayHeader, count: totalOfContacts };
        group.contacts.push(contact);
        if (group.contacts.length === 1) result.push(group);
        displayHeader = false;
    }
    return result;
});
