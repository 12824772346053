import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { debounceTime, defaultIfEmpty, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { combineLatest, from, Observable, of } from 'rxjs';
import algoliasearch from 'algoliasearch/lite';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { serverTimestamp } from '../helpers/firebase.helper';
import { getUserContactRequests, mapContactsToUsers, mapToContactRequest } from './rxjs-helpers/contact.helper';
import { joinWithUsers, wrapWithLoadingInformation } from './rxjs-helpers/common.helper';
import { watch } from 'rxjs-watcher';
import { AlgoliaContactSearch, ContactRequest, ContactSearch, DataBaseContactRequest, DataBaseContactRequestWithUserId, RequestStatus } from '../models/request.model';
import { ContactModel, ContactModelDataBase } from '../models/contact.model';
import { BaseService } from './base.service';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {

    private algoliaClient = algoliasearch(environment.algolia.appId, environment.algolia.searchApiKey).initIndex(environment.algolia.globalIndex);

    constructor(
        private contextService: ContextService,
        private angularFirestore: AngularFirestore) {
        super(contextService);
    }

    setFavourite(contact: ContactModel) {
        return this.angularFirestore.doc(`users/${this.currentUserUid}/contacts/${contact.uid}`).update({ favourite: contact.favourite });
    }
    delete(contactId: string) {
        return this.angularFirestore.doc(`users/${this.currentUserUid}/contacts/${contactId}`).delete();
    }
    getContacts() {
        return mapContactsToUsers(this.angularFirestore, this.currentUserUid);
    }

    getContact(uid: string, angularFirestore?: AngularFirestore, addStatus = false) {
        const afs = angularFirestore || this.angularFirestore;
        if (!addStatus)
            return afs.collection('users').doc<UserModel>(uid).valueChanges({ idField: 'uid' });
        else {
            const contactExist = this.angularFirestore.doc<ContactModelDataBase>(`users/${this.currentUserUid}/contacts/${uid}`).valueChanges();
            const contactRequest = this.angularFirestore.doc<DataBaseContactRequest>(`users/${uid}/contactRequests/${this.currentUserUid}`).valueChanges();
            const user = afs.collection('users').doc<UserModel>(uid).valueChanges({ idField: 'uid' });
            const result = combineLatest([user, contactExist, contactRequest]);
            return result.pipe(
                map(([u, c, r]) => {
                    console.log(u,c,r);
                    const usr: ContactModel = {
                        ...u,
                        requestStatus:this.mapRequestStatus(r),
                        isAdmin: null,
                        favourite: c?.favourite,
                        labels: c?.labels
                    }
                    return usr;
                })
            );
        }
    }

    getContactRequests() {
        return getUserContactRequests(this.angularFirestore, this.currentUserUid).pipe(
            joinWithUsers(this.angularFirestore, 'requestedBy'),
            mapToContactRequest,
            take(1),
            wrapWithLoadingInformation,
            watch('getContactRequests', 5),
        );
    }

    getNumberOfContactRequests() {
        return getUserContactRequests(this.angularFirestore, this.currentUserUid).pipe(
            map(results => results.length > 0 ? results.length : null)
        );
    }

    getRequestStatus(contactId) {
        return of(null);
    }

    createContactRequest(contactId) {
        const path = `users/${contactId}/contactRequests/${this.currentUserUid}`;
        return this.angularFirestore.doc(path).set({ requestedAt: serverTimestamp, requestedBy: this.currentUserUid, contactId: contactId, accepted: null });

    }

    acceptContactRequest(contactRequestId) {
        return this.angularFirestore.doc(`users/${this.currentUserUid}/contactRequests/${contactRequestId}`)
            .update({ accepted: true });
    }

    // /users/sYJDufuUmsTA8G8fIOYmJ6N80YC3/contactRequests/BcC8RmN7ZrflLXp7dkOqPQ0rFhL2
    // /users/BcC8RmN7ZrflLXp7dkOqPQ0rFhL2/contactRequests/sYJDufuUmsTA8G8fIOYmJ6N80YC3
    rejectContactRequest(contactRequestId: string) {
        return this.angularFirestore.doc(`users/${this.currentUserUid}/contactRequests/${contactRequestId}`).delete()
    }

    search(queryText) {
        if (!!!queryText) {
            return of(null as ContactSearch[]).pipe(wrapWithLoadingInformation);
        }
        return from(this.algoliaClient.search<AlgoliaContactSearch>(queryText, {
            hitsPerPage: 5,
            attributesToHighlight: [],
            cacheable: false,
            filters: `NOT id:"${this.currentUserUid}"`
        })).pipe(map(algoliaResult => algoliaResult.hits)).pipe(
            switchMap((hits) => {
                const requests = combineLatest(hits.map(h => h.objectID)
                    .map(u => this.getContactRequest(u, this.currentUserUid)))
                    .pipe(
                        defaultIfEmpty(null as DataBaseContactRequestWithUserId[])
                    );
                return combineLatest([of(hits), requests]);
            }),
            take(1),
            map(([hits, requests]) => {
                const result: ContactSearch[] = [];
                for (const hit of hits) {
                    const { firstName, lastName, id, livingTown, avatar, gender } = hit;
                    const request = requests.filter(el => el != null).find(r => r.userId === id);
                    result.push({ firstName, uid: id, lastName, livingTown, avatar, gender, requestStatus: this.mapRequestStatus(request) });
                }
                return result;
            }),
            wrapWithLoadingInformation,
            watch('serach contact', 5)
            // switchMap(usersId => combineLatest(usersId.map(userId => this.getContact(userId)))),
        )


    }

    private mapRequestStatus(request: DataBaseContactRequestWithUserId | DataBaseContactRequest): RequestStatus {
        if (!!!request) {
            return null;
        }
        if (request.accepted == null) {
            return RequestStatus.pending;
        }
        if (request.accepted) {
            return RequestStatus.accepted;
        }
    }

    private getContactRequest(userId: string, currentUserId: string) {
        return this.angularFirestore.collection('users').doc(userId).collection<DataBaseContactRequest>('contactRequests').doc(currentUserId)
            .valueChanges().pipe(map(r => {
                if (!!!r) {
                    return null;
                }
                return { ...r, userId } as DataBaseContactRequestWithUserId;
            }));
    }


}
