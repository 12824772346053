import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Mark6PreferredColorSchemeService} from '@devmonkeys/mark6/services';
import {ZoomService} from './services/zoom.service';
import {ContrastService} from './services/contrast.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private zoomService: ZoomService,
        private contrastService: ContrastService,
        private prefersColorSchemeService: Mark6PreferredColorSchemeService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            // Set light theme as default
            this.zoomService.load();
            this.contrastService.load();
            this.prefersColorSchemeService.update('light');
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

}
