import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { concat, Observable, of, pipe, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ContactService } from 'src/app/services/contact.service';
import { PlaceService } from 'src/app/services/places.service';

export interface UserSearchResult {
    loading: boolean;
    data?: UserResult[];
    empty?: boolean;
}

export interface UserResult {
    firstName: string;
    lastName: string;
    uid: string;
}

@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnDestroy {

    public control = new FormControl();
    public searchResult = new Subject<UserSearchResult>();

    private ngUnsubscribe = new Subject();

    @Input() controlToUpdate: FormControl;

    constructor(private contactService: ContactService) {
        this.control.valueChanges.pipe(
            this.throttleAndMap
        ).subscribe(v => this.searchResult.next(v));
    }

    setItem(item: UserResult) {
        this.control.patchValue(`${item.firstName} ${item.lastName}`, { emitEvent: false });
        this.searchResult.next(null);
        if (this.controlToUpdate) {
            this.controlToUpdate.patchValue(item.uid);
        }
    }

    throttleAndMap = (t: Observable<string>) =>
        t.pipe(
            tap(() => {
                this.searchResult.next(null);
                if (this.controlToUpdate) {
                    this.controlToUpdate.patchValue(null);
                }
            }),
            debounceTime(650),
            filter(searchTerm => searchTerm.length > 2),
            distinctUntilChanged(),
            switchMap(query => concat(
                of({ loading: true } as UserSearchResult),
                this.contactService.getContacts().pipe(
                    map(a => a.filter(c => (c.firstName + c.lastName).toLowerCase().includes(query.toLowerCase()) )),
                    map(r => new Object({
                        loading: false,
                        data: r,
                        empty: r.length === 0
                    }) as UserSearchResult))
            )),
            takeUntil(this.ngUnsubscribe)
        )

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
