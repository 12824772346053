import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';

@Directive({
    selector: '[fileSelector]'
})
export class FileSelectorDirective implements OnDestroy {
    @Output() fileSelected = new EventEmitter<File>();

    private uploadInput: HTMLInputElement;
    private busy = false
    @HostListener('click') click() {
        if (this.busy) return;
        this.busy = true;
        this.showFileDialog();
    }

    constructor() {
        this.init();
    }

    private init() {
        const t = document.createElement('input') as HTMLInputElement;
        t.onchange = () => this.onInputFileChange(t);
        t.hidden = true;
        t.accept = 'image/*';
        t.type = 'file';
        t.multiple = false;
        this.uploadInput = t;
    }

    private async onInputFileChange(evt: HTMLInputElement) {
        console.log(evt)
        this.fileSelected.emit(evt.files.item(0));
        this.busy = false;
    }

    // Show native file dialog
    private showFileDialog() {
        // reset the input in order to always display the file dialog
        this.uploadInput.value = null;
        this.uploadInput.click();
    }

    ngOnDestroy() {
        this.uploadInput.remove();
    }

}
