import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable, of, Subject, timer } from 'rxjs';
import { defaultIfEmpty, delay, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserChatRoom } from 'src/app/models/messenger.model';
import { MessengerService } from 'src/app/services/messenger.service';
import { ModalRoomInfoComponent } from '../../modals/modal-room-info/modal-room-info.component';

@Component({
    selector: 'app-messenger-rooms-list',
    templateUrl: './messenger-rooms-list.component.html',
    styleUrls: ['./messenger-rooms-list.component.scss'],
})
export class MessengerRoomsListComponent implements OnDestroy {

    regionalRooms$: Observable<UserChatRoom[]>;
    ownRooms$: Observable<UserChatRoom[]>;
    personRooms$: Observable<UserChatRoom[]>;
    ngUnsubscribe = new Subject<void>();
    currentRoomId: string;

    constructor(
        private route: ActivatedRoute, private router: Router,
        private messengerService: MessengerService,
        public modalController: ModalController
    ) {

        const rooms = messengerService.getUserRooms().pipe(this.yieldTotalUnreadCount);
        this.regionalRooms$ = rooms.pipe(
            map((a) => a.filter((aa) => aa.type === 1))
        );
        this.ownRooms$ = rooms.pipe(
            map((a) => a.filter((aa) => aa.type === 2))
        );
        this.personRooms$ = rooms.pipe(
            map((a) => a.filter((aa) => aa.type === 0))
        );


        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                switchMap((event: Event) => this.route.root.firstChild?.firstChild?.firstChild?.firstChild?.paramMap.pipe(map((paramMap: any) => paramMap.get('roomId')))),
                delay(1000),
                takeUntil(this.ngUnsubscribe)
            ).subscribe((roomId) => this.currentRoomId = roomId);

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    trackByRoomId(index, room) {
        return room.id;
    }

    async editRoom(room: UserChatRoom) {
        const modal = await this.modalController.create({
            component: ModalRoomInfoComponent,
            componentProps: { room },
            cssClass: 'modal-styles modal-edit-own-room'
        });
        return await modal.present();
    }

    async createOwnRoom() {
        const modal = await this.modalController.create({
            component: ModalRoomInfoComponent,
            cssClass: 'modal-styles modal-create-own-room'
        });
        return await modal.present();
    }

    private yieldTotalUnreadCount = tap((rooms: UserChatRoom[]) => {
        const total = rooms.reduce((sum, current) => {
            if (current.id === this.currentRoomId) return sum;
            return sum + (current.count ?? 0);
        }, 0);
        this.messengerService.setUnreadTotalCount(total);
    })
}
