import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserModel } from '../models/user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { serverTimestamp } from '../helpers/firebase.helper';
import { BaseService } from './base.service';
import { Call, CallDataBase, CallStatus } from '../models/call.model';
import { joinWithUser, joinWithUsers } from './rxjs-helpers/common.helper';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ContextService } from './context.service';
import { ModalController } from '@ionic/angular';
import { ModalIncomingCallComponent } from '../shared/modals/modal-incoming-call/modal-incoming-call.component';
import { ModalCallComponent } from '../shared/modals/modal-call/modal-call.component';

@Injectable({ providedIn: 'root' })
export class CallService extends BaseService {


    constructor(
        private modalController: ModalController,
        private contextService: ContextService,
        private angularFirestore: AngularFirestore) {
        super(contextService);
    }


    getCallStatus(userId, callId) {
        return this.angularFirestore.doc<CallDataBase>(`users/${userId}/calls/${callId}`).valueChanges().pipe(map(c => c.status));
    }



    setCallStatus(call: Call, status: CallStatus) {
        return this.angularFirestore.doc(`users/${call.receiver.uid}/calls/${call.callId}`).update({ status });
    }


    getIncomingCalls() {
        return this.angularFirestore
            .collection<Call>(`users/${this.currentUserUid}/calls`,
                ref => ref
                    .where('calledAt', '>', new Date())
                    .orderBy('calledAt', 'desc')
                    .limit(1)
            ).snapshotChanges().pipe(
                map(a => a.filter(a => a.payload.type === 'added')),
                filter(a => a && a.length > 0),
                map((snapshots) => snapshots.map(s => s.payload.doc.data())[0]),
                map(c => {
                    c.receiver = { uid: this.currentUserUid, email: null };
                    return c;
                }),
                joinWithUser(this.angularFirestore, 'callerId', 'caller')
            );
    }



    callRequest(user: UserModel) {

        const callId = this.angularFirestore.createId();
        return this.angularFirestore
            .doc(`users/${user.uid}/calls/${callId}`)
            .set({
                callId,
                callerId: this.currentUserUid,
                calledAt: serverTimestamp,
            }).then(() => new Object({
                callId,
                callerId: this.currentUserUid,
                caller: { uid: this.currentUserUid, email: null },
                receiver: user,
                calledAt: serverTimestamp,
            }) as Call);
    }



    audio: HTMLAudioElement;
    ringtone(action: 'play' | 'stop') {
        if (action === 'play') {
            this.audio = new Audio();
            this.audio.src = '/assets/audio/cool-cooler-and-better.mp2';
            this.audio.load();
            this.audio.play();
        }
        if (action === 'stop') {
            this.audio.pause();
        }
    }
    async call(user: UserModel) {
        this.modalController.dismiss();
        const callData = await this.callRequest(user);
        callData.status = CallStatus.calling;
        this.ringtone('play');
        const modal = await this.modalController.create({
            component: ModalIncomingCallComponent,
            componentProps: { call: callData },
            cssClass: 'modal-styles incoming-call',
            backdropDismiss: false,

        });
        await modal.present();
        const dismissed = await modal.onWillDismiss();
        this.ringtone('stop');

        if (dismissed.data.status) {
            this.setCallStatus(callData, dismissed.data.status as CallStatus);
        }
        if (dismissed.data.roomId) {
            this.callAccepted(dismissed.data.roomId);
        }
    }


    async callAccepted(roomId) {
        const modal = await this.modalController.create({
            component: ModalCallComponent,
            componentProps: { roomId },
            cssClass: 'modal-styles call',
            backdropDismiss: false
        });
        await modal.present();
    }
}
