import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Pipe({
    name: 'delay'
})

export class DelayPipe implements PipeTransform {
    transform(value: any, shouldDelay = false, delayInMs = 500): any {
        if (shouldDelay){
        return of(value).pipe(delay(delayInMs));

        }
        return of(value);   
    }
}