import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactModel } from 'src/app/models/contact.model';

@Component({
    selector: 'app-modal-add-contacts',
    templateUrl: './modal-add-contacts.component.html',
    styleUrls: ['./modal-add-contacts.component.scss'],
})
export class ModalAddContactsComponent {
    @Input() selectedUsers: ContactModel[];
    contacts: ContactModel[];
    constructor(private modalCtrl: ModalController) {
    }

    dismissModal() {
        this.modalCtrl.dismiss({
            dismissed: true,
            contacts: this.contacts
        });
    }

}
