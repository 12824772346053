import {Component} from '@angular/core';
import {ModalMyProfileComponent} from '../../modals/modal-my-profile/modal-my-profile.component';
import {ModalController, PopoverController} from '@ionic/angular';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {

    constructor(
        public modalController: ModalController,
        public popoverController: PopoverController
    ) {
    }

    async openModalMyProfile() {
        const modal = await this.modalController.create({
            component: ModalMyProfileComponent,
            cssClass: 'modal-styles auto-height'
        });
        return await modal.present();
    }

    public closePopover() {
        this.popoverController.dismiss();
    }

}
