import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';
import {ContextService} from 'src/app/services/context.service';

@Injectable({providedIn: 'root'})
export class ContextGuard implements CanActivate {

    constructor(private contextService: ContextService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.contextService.init().pipe(map(() => true));
    }

}
