import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-modal-label-delete-confirmation',
    templateUrl: './modal-label-delete-confirmation.component.html',
    styleUrls: ['./modal-label-delete-confirmation.component.scss'],
})
export class ModalLabelDeleteConfirmationComponent {

    @Input() count;
    @Input() key;
    form: FormGroup;

    constructor(
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            option: ['deleteLabel', [Validators.required]] // default value
        });
    }

    dismissModal(option?: 'deleteLabel' | 'deleteAll') {
        this.modalCtrl.dismiss({
            dismissed: true,
            option
        });
    }

}
