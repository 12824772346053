import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {concat, Observable, of, pipe, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {PlaceService} from 'src/app/services/places.service';

export interface AddressSearchResult {
    loading: boolean;
    data?: AddressResult[];
    empty?: boolean;
}

export interface AddressResult {
    description: string;
    place_id: string;
}

@Component({
    selector: 'app-address-search',
    templateUrl: './address-search.component.html',
    styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnDestroy {

    public control = new FormControl();
    public searchResult = new Subject<AddressSearchResult>();

    private ngUnsubscribe = new Subject();

    @Input() controlToUpdate: FormControl;

    constructor(private placeService: PlaceService) {
        this.control.valueChanges.pipe(
            this.throttleAndMap
        ).subscribe(v => this.searchResult.next(v));
    }

    setAddress(item: AddressResult) {
        this.control.patchValue(item.description, {emitEvent: false});
        this.searchResult.next(null);
        if (this.controlToUpdate) {
            this.controlToUpdate.patchValue(item.place_id);
        }
    }

    throttleAndMap = (t: Observable<string>) =>
        t.pipe(
            tap(() => {
                this.searchResult.next(null);
                if (this.controlToUpdate) {
                    this.controlToUpdate.patchValue(null);
                }
            }),
            debounceTime(650),
            filter(searchTerm => searchTerm.length > 2),
            distinctUntilChanged(),
            switchMap(query => concat(
                of({loading: true} as AddressSearchResult),
                this.placeService.getPlaces(query).pipe(map(r => new Object({
                    loading: false,
                    data: r,
                    empty: r.length === 0
                }) as AddressSearchResult))
            )),
            takeUntil(this.ngUnsubscribe)
        )

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
