import {Pipe, PipeTransform} from '@angular/core';
import {mapAvatar} from 'src/app/helpers/avatar.helper';
import {Gender} from 'src/app/models/user.model';

@Pipe({
    name: 'size'
})

export class SizePipe implements PipeTransform {
    transform(value: any, size: number, gender: Gender, defaultValue: string = null): any {
        if ((!!!value || !!!value.sizes || !!!value.sizes[size]) && !!!defaultValue) {
            return mapAvatar(gender);
        } else if ((!!!value || !!!value.sizes || !!!value.sizes[size]) && defaultValue) {
            return defaultValue;
        }
        return value.sizes[size];
    }
}
